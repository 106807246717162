enum Enums{
    name='Name',
    firstName='First Name',
    lastName='Last Name',
    mobileNumber='Mobile Number',
    component='Component',
    role='Role',
    status='Status',
    VERIFY='Verify',
    CONFIRM_VERIFICATION='Confirm Verification',
    DECLINE='Decline',
    DELCINE_VERIFICATION='Decline Verification',
    GET_UNVERIFIED_USERS= 'GET UNVERIFIED USERS',
    USER_VERIFIED_SUCCESS='User was successfully verified!',
    USER_UNVERIFIED_SUCCESS='User was successfully unverified!'
}

export default Enums;