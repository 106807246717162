import { extendTheme } from "@chakra-ui/react";
import type { StyleFunctionProps } from '@chakra-ui/styled-system';
import { ButtonStyle as Button } from "./components/Button";
import { InputStyle as Input } from './components/Input'
import { TextStyle as Text } from './components/Text';
import { formLabelStyle as FormLabel} from './components/FormLabel';
import { LinkStyle as Link } from './components/Link';

const breakpoints = {
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',
 }


 const theme = extendTheme({
    breakpoints,
    colors:{
        primary: "#1e293b",
        secondary: "red",
        muted: '#64748b',
        tertiary: 'teal',
        offwhite: '#EDF2F7',
        foreground: '#2D3748',
        background: '#1a202c',
    },
    components: {
        Button,
        Input,
        Text,
        Link,
        FormLabel

    },

    textStyles: {
        normal: {
            fontSize: {base: '16px', lg: '1.2vw'},
          },
     },


    styles: {
        global: (props : StyleFunctionProps) => ({
          "html, body": {
            fontSize: ["sm", "md"],
            color: props.theme.colors.gray[600],
            lineHeight: "tall",
            fontFamily: `'Lato', sans-serif`,
  
          },
    
        })
      }
});

export default theme;