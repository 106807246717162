import { authAxios as axios } from "../../config/axios";
import endpoints from "../../utils/endpoints";

class Services {
  async saveGrazeon(payload: any) {
    const response = await axios({
      url: endpoints.saveGrazeon,
      method: "POST",
      data: payload,
      headers: {
        Authorization: localStorage.getItem("MC_SUPER_ADMIN"),
      },
    });

    return response.data;
  }

  async getGrazeonById(grazeonId: any) {
    const response = await axios({
      url: endpoints.getGrazeon(grazeonId),
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("MC_SUPER_ADMIN"),
      },
    });

    return response.data[0];
  }
  async addMedia(payload: any) {
    const response = await axios({
      method: "POST",
      url: endpoints.addMedia,
      data: payload,
      headers: {
        Authorization: localStorage.getItem("MC_SUPER_ADMIN"),
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
  async deleteMedia(mediaId: string) {
    const response = await axios({
      method: "DELETE",
      url: endpoints.deleteMedia(mediaId),
      headers: {
        Authorization: localStorage.getItem("MC_SUPER_ADMIN"),
      },
    });
    return response.data;
  }

  async getMobileConfigs() {
    const response = await axios({
      method: "GET",
      url: endpoints.mobileConfig,
    });
    return response.data;
  }
  async updateGrazeon(grazeonId: any, payload: any) {
    const response = await axios({
      method: "PATCH",
      data: payload,
      url: endpoints.updateGrazeon(grazeonId),
      headers: {
        Authorization: localStorage.getItem("MC_SUPER_ADMIN"),
      },
    });
    return response.data[0];
  }

  async deleteRoles(grazeonId: any, component: string, role: string) {
    const response = await axios({
      method: "DELETE",
      url: endpoints.deleteGrazeonRole(grazeonId, component, role),
      headers: {
        Authorization: localStorage.getItem("MC_SUPER_ADMIN"),
      },
    });
    return response.data[0];
  }
}

export default new Services();
