import { useState, useMemo, useEffect, useCallback } from 'react';
import { IconButton, Box, useDisclosure } from '@chakra-ui/react';
import { RiDeleteBin6Line } from 'react-icons/ri';

import MediaComponent from '../../../components/MediaComponent';
import CardScaffold from '../../../components/CardScaffold';
import ActiveButton from './ActiveButton';
import PriorityButton from './PriorityButton';
import SubmitAndCloseButton from '../../../components/SubmitAndCloseButton';
import ModalComponent from '../../../components/ModalComponent';
import Enums from '../enums';

import { Banner } from '../types';
import { useDeleteBanner, useUpdateBanner } from '../queryHooks';


const BannerCard = (props: Banner) => {
  const { onClose, isOpen, onOpen } = useDisclosure();
  const {
    mutate: deleteBanner,
    isLoading: deletingBanner,
    isSuccess: bannerDeleted,
  } = useDeleteBanner();
  const {
    mutate: updateBanner,
    isLoading: updatingBanners,
  } = useUpdateBanner();
  const [whichUpdate, setWhichUpdate] = useState('');

  useEffect(() => {
    if (bannerDeleted) {
      onClose();
    }
  }, [bannerDeleted, onClose]);

  const handlePriorityChange = (value: number) => {
    setWhichUpdate(Enums.PRIORITY);
    updateBanner({ bannerId: props._id, media: props.media, data: { priority: value } });
  };

  const handleActiveChange = (value: boolean) => {
    setWhichUpdate(Enums.ACTIVE_STATE);
    updateBanner({ bannerId: props._id, media: props.media, data: { active: value } });
  };

  const handleDeleteBanner = useCallback(() => {
    deleteBanner({ bannerId: props._id });
  }, [deleteBanner, props._id]);

  const bottomButton = useMemo(() => {
    return (
      <SubmitAndCloseButton
        isLoading={deletingBanner}
        onSubmit={handleDeleteBanner}
        onClose={onClose}
        variant='delete'
      />
    );
  }, [deletingBanner, handleDeleteBanner, onClose]);

  return (
    <>
      <CardScaffold
        media={
          <MediaComponent
            path={props.media.path}
            isVideo={!props.media!.mimeType.startsWith('image')}
          />
        }
        leftMenu={
          <>
            <ActiveButton
              isLoading={updatingBanners && whichUpdate === Enums.ACTIVE_STATE}
              active={props.active}
              setActive={handleActiveChange}
            />
            <Box>
              <PriorityButton
                isLoading={updatingBanners && whichUpdate === Enums.PRIORITY}
                priority={props.priority}
                setPriority={handlePriorityChange}
              />
            </Box>
          </>
        }
        rightMenu={
          <>
            <IconButton
              onClick={onOpen}
              isRound
              variant='ghost'
              size='md'
              colorScheme='red'
              icon={<RiDeleteBin6Line />}
              aria-label={''}
            />
          </>
        }
      />
      <ModalComponent
        button={bottomButton}
        isOpen={isOpen}
        title={Enums.DELETE_BANNER}
        onClose={onClose}
      >
        <Box>This will delete banner from the Customer Mobile Apps. Are you sure you want to delete?</Box>
      </ModalComponent>
    </>
  );
};
export default BannerCard;
