import Select, { MultiValue } from "react-select";

type GaraazSelectProps = {
  value: any;
  options: any;
  onChange: (e: MultiValue<any>) => void;
  isMulti: boolean;
};

const GaraazSelect: React.FC<GaraazSelectProps> = ({
  value,
  options,
  onChange,
  isMulti,
}) => {
  return (
    <Select
      name="brands"
      placeholder={"Select Brands"}
      value={value}
      options={options}
      onChange={(e) => {
        onChange(e);
      }}
      isMulti={isMulti}
      required={false}
    />
  );
};

export default GaraazSelect;
