import endpoints from "../../utils/endpoints";
import { authAxios as axios } from "../../config/axios";
import globalConstant from "../../utils/constants";

class Services {
  async getCustomerDetails(mobileNumber: string) {
    const response = await axios({
      method: globalConstant.GET,
      url: `${endpoints.getCustomer(mobileNumber)}`,
    });
    return response.data;
  }

  async getSupplierDetails(supplierId: string) {
    const response = await axios({
      method: globalConstant.GET,
      url: `${endpoints.getSupplierInfo(supplierId)}`,
    });
    return response.data;
  }

  async createCustomerByRole({
    customerBasicData,
    customerAddressData = null,
  }: {
    customerBasicData: any;
    customerAddressData?: any;
  }) {
    const response = await axios({
      method: globalConstant.POST,
      url: `${endpoints.createCustomer}`,
      data: customerBasicData,
    });
    if (customerAddressData) {
      await axios({
        method: globalConstant.POST,
        url: `${endpoints.createAddress(response.data._id)}`,
        data: customerAddressData,
      });
    }
    return response.data;
  }

  async getCitiesAndStates() {
    const response = await axios({
      method: globalConstant.GET,
      url: `${endpoints.getCitiesAndStates}`,
    });
    return response.data;
  }

  async getMobileConfigs() {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.mobileConfig,
    });
    return response.data;
  }

  async editCustomerDetails({
    customerId,
    customerData,
  }: {
    customerId: string;
    customerData: any;
  }) {
    const response = await axios({
      method: globalConstant.PATCH,
      url: endpoints.editCustomerDetails(customerId),
      data: customerData,
    });
    return response.data;
  }

  async getAllSuppliers() {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getAllSuppliersV2,
    });
    return response.data;
  }

  async deleteSupplierAssociation(customerId: string, supplierId: string) {
    const response = await axios({
      method: globalConstant.DELETE,
      url: endpoints.deleteSupplierAssociationByIds(customerId, supplierId),
    });
    return response.data;
  }
}

export default new Services();
