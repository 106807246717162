import { useQuery } from "@tanstack/react-query";
import Services from './services';

enum Enum {
    LIVE_DASHBOARD_DATA = 'LIVE_DASHBOARD_DATA'
}

export const useDailyUrlDashboard = () => {
    return useQuery(
        [Enum.LIVE_DASHBOARD_DATA],
        Services.getdailyUrlDashboard,
        { refetchInterval: 600000 }
    );
}