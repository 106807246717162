import { useToast  } from "@chakra-ui/react";

import globalConstants from './constants';

type statusType = "error" | "loading" | "info" | "warning" | "success" | undefined


type Props = {
    title?: string,
    description : string,
    status: string
}


const useNotification = () => {
    const toast = useToast();
    return (props: Props)  => (
        toast({
            title: props.title,
            description: props.description,
            status: props.status as statusType,
            duration: parseInt(globalConstants.TOAST_TIMEOUT),
            isClosable: true,
            position: "top",
          })
    )
}


export default useNotification;