import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";

import axios from "axios";
import React from "react";
import { supplierFormData } from "./AddSupplier";

type AddressDetailsFormSupplierProps = {
  formData: supplierFormData;
  setFormData: React.Dispatch<React.SetStateAction<supplierFormData>>;
};

const AddressDetailsFormSupplier: React.FC<AddressDetailsFormSupplierProps> = ({
  formData,
  setFormData,
}) => {
  const [statesCitiesData, setStatesCitiesData] = React.useState<any>([]);

  React.useEffect(() => {
    async function fetchStatesAndCities() {
      const token = localStorage.getItem("MC_SUPER_ADMIN");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/utility/state-city`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        setStatesCitiesData(response.data);
      } catch (error) {
        console.error("Error fetching states and cities:", error);
      }
    }

    fetchStatesAndCities();
  }, []);

  const handleAddressDetailsChange = (
    second:
      | "addressLine1"
      | "addressLine2"
      | "state"
      | "pinCode"
      | "city"
      | "country",

    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setFormData((previousData) => {
      const newData = {
        ...previousData,
        addressDetails: {
          ...previousData.addressDetails,
          [second]: e.target.value,
        },
      };
      return newData;
    });
  };

  return (
    <Box width="100%">
      <Flex align="start" mb={4} width="100%">
        <FormControl flex="1" mr={4}>
          <FormLabel
            style={{
              fontSize: "12px",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
            htmlFor={"Address Line 1"}
            color="#64748b"
          >
            Address Line 1
          </FormLabel>
          <Input
            value={formData.addressDetails.addressLine1}
            type="text"
            id={"Address Line 1"}
            placeholder={`Enter Address Line 1`}
            onChange={(e) => {
              handleAddressDetailsChange("addressLine1", e);
            }}
          />
        </FormControl>
        <FormControl flex="1">
          <FormLabel
            style={{
              fontSize: "12px",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
            htmlFor={"Address Line 2"}
            color="#64748b"
          >
            Address Line 2
          </FormLabel>
          <Input
            value={formData.addressDetails.addressLine2}
            type="text"
            id={"Address Line 2"}
            placeholder={`Enter Address Line 2`}
            onChange={(e) => {
              handleAddressDetailsChange("addressLine2", e);
            }}
          />
        </FormControl>
      </Flex>

      <Flex align="start" mb={4} width="100%">
        <FormControl flex="1" mr={4}>
          <FormLabel
            style={{
              fontSize: "12px",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
            htmlFor={"State"}
            color="#64748b"
          >
            State
          </FormLabel>
          <Select
            onChange={(event) => {
              handleAddressDetailsChange("state", event);
            }}
            value={formData.addressDetails.state}
            placeholder="Please Select a State"
            id="State"
          >
            {Object.entries(statesCitiesData).map(([key, value], index) => {
              return (
                <option key={index} value={key}>
                  {key}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <FormControl flex="1">
          <FormLabel
            style={{
              fontSize: "12px",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
            htmlFor={"Pin Code"}
            color="#64748b"
          >
            PIN CODE
          </FormLabel>
          <Input
            value={formData.addressDetails.pinCode}
            type="text"
            id={"Pin Code"}
            placeholder={`Enter PIN code`}
            onChange={(e) => {
              handleAddressDetailsChange("pinCode", e);
            }}
          />
        </FormControl>
      </Flex>

      <Flex align="start" mb={4} width="100%">
        <FormControl flex="1" mr={4}>
          <FormLabel
            style={{
              fontSize: "12px",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
            htmlFor={"City"}
            color="#64748b"
          >
            City
          </FormLabel>
          <Select
            placeholder="Please Select City"
            onChange={(event) => {
              handleAddressDetailsChange("city", event);
            }}
            value={formData.addressDetails.city}
            id="City"
          >
            {statesCitiesData[formData.addressDetails.state]?.map(
              (cityOption: any, index: number) => {
                return <option key={index}>{cityOption}</option>;
              }
            )}
          </Select>
        </FormControl>
        <FormControl flex="1">
          <FormLabel
            style={{
              fontSize: "12px",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
            htmlFor={"Country"}
            color="#64748b"
          >
            Country
          </FormLabel>
          <Input
            isDisabled={true}
            value={"INDIA"}
            type="text"
            id={"Country"}
            placeholder={`Enter Country`}
          />
        </FormControl>
      </Flex>
    </Box>
  );
};

export default AddressDetailsFormSupplier;
