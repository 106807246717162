import { useQuery } from "@tanstack/react-query";
import services from "./services";

export const useGetGrazeonsInfo = (grazeonId: any) => {
  return useQuery([grazeonId], () => services.getGrazeonById(grazeonId));
};

export const useGetMobileConfigs = () => {
  return useQuery(["mobileConfigs"], () => services.getMobileConfigs());
};
