import { Box, flexbox, Text } from "@chakra-ui/react";

interface Prop {
  title: string;
  value: string;
}

const DashboardCard = (props: Prop) => {
  let title = props.title;
  let link = props.value;

  const handleClick = (url: string) => {
    window.open(url, "_blank")?.focus();
  };

  return (
    <Box
    borderRadius='md'
    width={{ base: '100%', lg: '30.3%' }}
    height={"26.3vh"}
    py={10}
    boxShadow='dark-lg'
    gap={5}
    alignSelf='center'
    mt={5}
    bg='#1E293B'
    cursor={"pointer"}
    onClick={() => handleClick(String(link))}
    >
      <Text
        color='white'
        px="4"
        alignSelf="center" 
        textTransform='capitalize' 
        textAlign="center" 
        fontWeight='extrabold' 
        fontSize="20"
        mt={10}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {title || 0}
      </Text>
    </Box>

  );
};

export default DashboardCard;
