import {
  IconButton,
  Button,
  Box,
  useDisclosure,
  Flex,
  FormControl,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import { MdDelete, MdEdit } from "react-icons/md";
import PopupModal from "../../../components/PopupModal";
import GaraazIconButton from "../../../components/GaraazIcon";
import GaraazFormLabel from "../../../components/GarrazFormLabel";
import { useState } from "react";
import GaraazAlertDialog from "../../../components/GaraazAlertDialog";

type ActionCellProps = {
  row: any;
  uniqueUPNs: any;
  onEditOk: (UPN: string, partNumber: string) => void;
  onDelete: (UPN: string) => void;
};

const ActionCell: React.FC<ActionCellProps> = ({
  row,
  uniqueUPNs,
  onEditOk,
  onDelete,
}) => {
  const [selectedUPN, setSelectedUPN] = useState(row.partId.UPN);
  const [selectedPartNumber, setSelectedPartNumber] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();
  return (
    <Box display={"flex"} justifyContent={"center"}>
      <GaraazIconButton
        onClick={onDeleteModalOpen}
        icon={<MdDelete></MdDelete>}
        isDisabled={row.isVerified}
      ></GaraazIconButton>
      <GaraazIconButton
        onClick={onOpen}
        icon={<MdEdit></MdEdit>}
        style={{ marginLeft: "0.5em" }}
        isDisabled={row.isVerified}
      ></GaraazIconButton>

      <PopupModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setSelectedPartNumber("");
        }}
        bodyText={
          <>
            <Flex
              alignItems="center"
              justify="space-between"
              mb={4}
              width={"70%"}
            >
              <FormControl width={"25%"} id="UPN" flex={1}>
                <GaraazFormLabel text="UPN" isRequired={true} />
              </FormControl>
              <Flex width={"75%"}>
                <FormControl flex={1}>
                  <Select
                    placeholder={"Select UPN"}
                    name="UPN"
                    value={selectedUPN}
                    onChange={(e) => {
                      setSelectedUPN(e.target.value);
                    }}
                    isDisabled={true}
                  >
                    {uniqueUPNs.map((UPN: any, index: any) => (
                      <option value={UPN} key={`${UPN}-${index}`}>
                        {UPN}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
            </Flex>
            <Flex
              alignItems="center"
              justify="space-between"
              mb={4}
              width={"70%"}
            >
              <FormControl width={"25%"} id="Part Number" flex={1}>
                <GaraazFormLabel text="Part Number" isRequired={true} />
              </FormControl>
              <Flex width={"75%"}>
                <FormControl flex={1}>
                  <Input
                    type="text"
                    placeholder="Enter Part Number"
                    name="number"
                    value={selectedPartNumber}
                    onChange={(e) => {
                      setSelectedPartNumber(e.target.value);
                    }}
                  />
                </FormControl>
              </Flex>
            </Flex>
          </>
        }
        title={"Edit Spare Part"}
        hasFooter={true}
        secondaryActionButtonText="Confirm"
        onOk={() => {
          onEditOk(row.partId.UPN, selectedPartNumber);
          onClose();
          setSelectedPartNumber("");
        }}
        onCancel={() => {
          onClose();
          setSelectedPartNumber("");
        }}
        isOkButtonDisabled={
          selectedPartNumber.trim() === "" || selectedUPN.trim() === ""
        }
      ></PopupModal>

      <GaraazAlertDialog
        onSubmitButtonClick={() => {
          onDelete(row.partId.UPN);
          onDeleteModalClose();
        }}
        submitBtnText={"Delete"}
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        bodyText={
          <Box display={"flex"}>
            <Text> Are you sure to delete </Text>
            <Text fontWeight={"bold"} ml={2}>
              {" "}
              {row.partId.UPN}{" "}
            </Text>
          </Box>
        }
        headerText={"Delete Car Part"}
        branchName={""}
      ></GaraazAlertDialog>
    </Box>
  );
};

export default ActionCell;
