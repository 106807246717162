import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import React from "react";

type GaraazAlertDialogProps = {
  onSubmitButtonClick: () => void;
  submitBtnText: string;
  isOpen: boolean;
  onClose: () => void;
  bodyText: any;
  headerText: string;
  branchName: string;
};

const GaraazAlertDialog: React.FC<GaraazAlertDialogProps> = ({
  onSubmitButtonClick,
  submitBtnText,
  isOpen,
  onClose,
  bodyText,
  headerText,
  branchName,
}) => {
  const cancelRef = React.useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
      returnFocusOnClose={false}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {headerText}
          </AlertDialogHeader>
          <AlertDialogBody>
            {bodyText} <strong>{branchName.toUpperCase()}</strong>{" "}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              _hover={{ bg: "none !important" }}
              bg="white !important"
              ref={cancelRef}
              onClick={onClose}
              color={"blackAlpha.800"}
              borderColor={"black"}
              borderWidth={2}
            >
              Cancel
            </Button>
            <Button
              _hover={{ bg: "red.500 !important" }}
              bg="red.400"
              onClick={onSubmitButtonClick}
              ml={3}
            >
              {submitBtnText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default GaraazAlertDialog;
