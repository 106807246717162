import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import _ from "lodash";

type GeneralTableProps = {
  data: any;
  columns: any;
};

const GaraazGeneralTable: React.FC<GeneralTableProps> = ({ data, columns }) => {
  return (
    <TableContainer>
      <Table fontSize={"xs"}>
        <Thead>
          <Tr
            backgroundColor={"#f5f5f5"}
            borderTop={"1px solid #dee2e6"}
            borderBottom={"1px solid #dee2e6"}
          >
            {columns.map((column: any) => {
              return (
                <Th
                  key={`${column.header}`}
                  color={"black"}
                  borderRight={"1px solid #dee2e6"}
                  borderLeft={"1px solid #dee2e6"}
                  width={column.width}
                >
                  {column.header}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row: any, index: number) => {
            return (
              <Tr _hover={{ backgroundColor: "#ececec" }} key={row._id}>
                {columns.map((column: any) => {
                  return (
                    <Td
                      key={`${column.header}-${row._id}`}
                      p={2}
                      paddingLeft={3}
                      borderBottomColor={"#Dee2e6"}
                      borderRight={"1px solid #dee2e6"}
                      borderLeft={"1px solid #dee2e6"}
                    >
                      {column.value
                        ? column.value
                        : column.accessor
                        ? _.get(row, column.accessor)
                        : column.renderCell(row)}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default GaraazGeneralTable;
