import endpoints from '../../utils/endpoints';
import { authAxios as axios } from '../../config/axios';
import globalConstant from '../../utils/constants';
import modifyMobileConfig from './helper/modifyMobileConfig';
import { MobileConfig } from './types'



class Services {
    async getMobileConfigs() {
        const response = await axios({
            method: globalConstant.GET,
            url: endpoints.mobileConfig
        })
        return modifyMobileConfig(response.data)
    }

    async createMobileConfig(payload: {data : MobileConfig}){
        const response = await axios({
            method: globalConstant.POST,
            url: endpoints.mobileConfig,
            data: payload.data
        })
        return response.data
    }

    async deleteMobileConfig(payload: {data : string[]}){
        const response = await axios({
            method: globalConstant.DELETE,
            url: endpoints.mobileConfig,
            data: payload.data
        })
        return response.data
    }
}

export default new Services()