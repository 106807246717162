import axios from "axios";
import React, { useEffect, useState } from "react";

import Select, { MultiValue } from "react-select";

import { Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { supplierFormData } from "./AddSupplier";
import GaraazSelect from "../../../components/GaraazSelect";

export interface BrandOption {
  value: string;
  label: string;
}

type OthersDetailsFormSupplierProps = {
  formData: supplierFormData;
  setFormData: React.Dispatch<React.SetStateAction<supplierFormData>>;
  isPrefilled?: boolean;
};

const OthersDetailsFormSupplier: React.FC<OthersDetailsFormSupplierProps> = ({
  formData,
  setFormData,
  isPrefilled,
}) => {
  const [brands, setBrands] = useState<BrandOption[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<any>([]);

  useEffect(() => {
    async function fetchBrands() {
      const token = localStorage.getItem("MC_SUPER_ADMIN");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/brands`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        setBrands(
          response.data.map((brand: any) => ({
            value: brand._id,
            label: brand.name,
          }))
        );
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    }

    fetchBrands();
  }, []);

  useEffect(() => {
    if (isPrefilled) {
      const selectedBrandsTemp = brands.filter((brand) =>
        formData.others.brands.includes(brand.value)
      );

      setSelectedBrands(selectedBrandsTemp);
    }
  }, [brands]);

  function handleBrandsChange(e: MultiValue<any>) {
    setSelectedBrands(e);
    setFormData((previousData) => {
      return {
        ...previousData,
        others: {
          ...previousData.others,
          brands: e.map((brand) => brand.value),
        },
      };
    });
  }

  return (
    <>
      <Flex>
        <FormControl flex={1} mr={4}>
          <FormLabel
            htmlFor="brands"
            style={{
              fontSize: "12px",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
          >
            Brands
          </FormLabel>
          <GaraazSelect
            value={selectedBrands}
            options={brands}
            onChange={handleBrandsChange}
            isMulti={true}
          ></GaraazSelect>
        </FormControl>

        <FormControl flex="1" mr={4}>
          <FormLabel
            style={{
              fontSize: "12px",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
            color="#64748b"
          >
            Unique Code
          </FormLabel>
          <Input
            type="text"
            placeholder={`Enter Unique Code`}
            value={formData.others.uniqueCode}
            onChange={(e) => {
              setFormData((previousData) => {
                let newData = {
                  ...previousData,
                  others: {
                    ...previousData.others,
                    uniqueCode: e.target.value,
                  },
                };
                return newData;
              });
            }}
          />
        </FormControl>
      </Flex>
    </>
  );
};

export default OthersDetailsFormSupplier;
