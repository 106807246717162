import {
  Box,
  Flex,
  FormControl,
  IconButton,
  Input,
  Select,
} from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import { pageTitles } from "../../utils/paths";
import GaraazInput from "../../components/GaraazInput";
import GaraazFormLabel from "../../components/GarrazFormLabel";
import { useEffect, useState } from "react";
import { authAxios as axios } from "../../config/axios";
import constants from "../../utils/constants";
import endpoints from "../../utils/endpoints";
import services from "./services";
import { BiSave } from "react-icons/bi";
import useNotification from "../../utils/useNotification";
import globalConstant from "../../utils/constants";

const Index = () => {
  const toast = useNotification();
  const [carData, setCarData] = useState<any[]>([]);

  const [generations, setGenerations] = useState<any[]>([]);
  const [names, setNames] = useState<any[]>([]);

  const [selectedGeneration, setSelectedGeneration] = useState("");
  const [selectedCar, setSelectedCar] = useState<any>({ name: "" });
  const [boodmoModel, setBoodmoModel] = useState("");

  useEffect(() => {
    fetchCarData();
  }, []);

  useEffect(() => {
    setNames(carData.filter((car) => car.generation === selectedGeneration));
  }, [selectedGeneration]);

  async function fetchCarData() {
    const carDataTemp = await services.getCars();

    setCarData(carDataTemp);
    const uniqueGenerations = new Set(
      carDataTemp.map((car: any) => car.generation)
    );

    setGenerations(Array.from(uniqueGenerations));
  }

  const handleGenerationChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedGeneration(event.target.value);
    setSelectedCar({ name: "" });
  };

  const handleCarChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const findedCar = carData.find((car) => car._id === event.target.value) ?? {
      boodmoModel: "",
    };
    setSelectedCar(findedCar);
    setBoodmoModel(findedCar.boodmoModel);
  };

  async function handleSaveButtonClick1() {
    try {
      const newCarData = await services.setBoodmoModel(
        selectedCar._id,
        boodmoModel
      );
      setBoodmoModel(newCarData.boodmoModel);
      toast({
        description: "Boodmo model updated successfully",
        status: globalConstant.Success,
      });
    } catch (error: any) {
      toast({
        description: error.message,
        status: globalConstant.Error,
      });
    }
  }

  return (
    <PageScaffold
      title={pageTitles.boodmoModel}
      hasButton={true}
      buttonText={
        <IconButton
          isDisabled={!selectedCar?._id}
          position={"fixed"}
          right={4}
          aria-label="saveBtn"
          size="md"
          color={"white"}
          icon={<BiSave size={"1.5rem"} />}
          onClick={handleSaveButtonClick1}
        />
      }
    >
      <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
        <FormControl width={"25%"} id="carGeneration" flex={1}>
          <GaraazFormLabel text="Car Generation" isRequired={false} />
        </FormControl>
        <Flex width={"75%"}>
          <Select
            placeholder={"Select Car Generation"}
            name="carGeneration"
            value={selectedGeneration}
            onChange={handleGenerationChange}
          >
            {generations.map((gen) => (
              <option key={gen} value={gen}>
                {gen}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
      <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
        <FormControl width={"25%"} id="carName" flex={1}>
          <GaraazFormLabel text="Car Name" isRequired={false} />
        </FormControl>
        <Flex width={"75%"}>
          <Select
            placeholder={"Select Car Name"}
            name="carName"
            value={selectedCar?._id}
            onChange={handleCarChange}
          >
            {names.map((car) => (
              <option key={car.name} value={car._id}>
                {car.name}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
      <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
        <FormControl width={"25%"} id="boodmoModel" flex={1}>
          <GaraazFormLabel text="Boodmo Model" isRequired={false} />
        </FormControl>
        <Flex width={"75%"}>
          <FormControl>
            <Input
              type="text"
              placeholder="Enter Boodmo Model"
              width={"100%"}
              name="boodmoModel"
              value={boodmoModel}
              onChange={(e) => {
                setBoodmoModel(e.target.value);
              }}
            />
          </FormControl>
        </Flex>
      </Flex>
    </PageScaffold>
  );
};

export default Index;
