import { Box, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import constants from "../utils/constants";
import { isUserPermitted } from "../utils/isUserVerified";
import AuthViewLayout from "../components/AuthViewLayout";

const initialUserDetails = {
  firstName: "",
  lastName: "",
  mobileNumber: "",
  userType: "",
  userSubType: "",
  roles: [],
  locale: "",
  updatedAt: "",
};

const UserDetailsContext = createContext<IUserDetails>(initialUserDetails);

enum localEnum {
  USERDETAILS = "UserDetails",
  REVIEWINGYOU = "We're reviewing you",
  VERIFYINGYOU = "It usually takes less then a day for us to complete the process.",
}

export const UserDetailsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userDetails, setUserDetails] =
    useState<IUserDetails>(initialUserDetails);
  const [userIsPermitted, setUserIsPermitted] = useState(true);

  const { data, isLoading } = useQuery([localEnum.USERDETAILS], async () => {
    const userData = JSON.parse(localStorage.MC_USER);
    const authJSON = localStorage.getItem(constants.MC_SUPER_ADMIN);
    const headers = { Authorization: `${authJSON}` };
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/users/${userData.mobileNumber}`,
      { headers }
    );
    setUserDetails(response?.data);
    setUserIsPermitted(isUserPermitted(response?.data.roles));
    localStorage.MC_USER = JSON.stringify(response?.data);
    return response?.data;
  });

  useEffect(() => {
    if (localStorage.MC_USER) {
      setUserDetails(JSON.parse(localStorage.MC_USER));
    }
  }, []);

  return userIsPermitted ? (
    <UserDetailsContext.Provider value={userDetails}>
      {children}
    </UserDetailsContext.Provider>
  ) : (
    <AuthViewLayout>
      <Box alignSelf="center">
        <Text textAlign="center" fontWeight="bold" fontSize="lg">
          {localEnum.REVIEWINGYOU}
        </Text>
        <Text textAlign="center" fontSize="md">
          {localEnum.VERIFYINGYOU}
        </Text>
      </Box>
    </AuthViewLayout>
  );
};

export const useUserDetailsContext = () => useContext(UserDetailsContext);
