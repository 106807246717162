import { Box, Select, Text } from "@chakra-ui/react";
import GaraazInput from "../../../components/GaraazInput";
import { messages } from "../constants";
import { useEffect, useState } from "react";
import { useGetCitiesAndStates } from "../queryHooks";
import { AddressDetailsType } from "../types";

type anotherFunctionType = {
  handleChangeAddressInputs?: (name: string, value: string) => void;
};

type addressDetailsPropTypes = AddressDetailsType & anotherFunctionType;

const AddressDetailsFormCustomer = (props: addressDetailsPropTypes) => {
  const { data } = useGetCitiesAndStates();
  const [cities, setCities] = useState<string[]>([]);
  const [state, setState] = useState<string>(props?.state);
  const [city, setCity] = useState<string>(props?.city);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (props.handleChangeAddressInputs) {
      props.handleChangeAddressInputs(name, value);
    }
  };

  const getCitiesData = () => {
    if (state && data && data.states) {
      const selectedState = data.states.find(
        (stat) => stat.toUpperCase() === (state as string)?.toUpperCase()
      );

      if (selectedState) {
        const citiesForSelectedState = data.cities[selectedState.toUpperCase()];
        setCities(
          Array.isArray(citiesForSelectedState) ? citiesForSelectedState : []
        );
      }
    }
  };

  useEffect(() => {
    getCitiesData();
  }, [state, data?.states]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" my="2">
        <GaraazInput
          width="48%"
          label={messages.addressLine1}
          placeholder={messages.addressLine1}
          inputName="addressLine1"
          inputType="text"
          isRequired={true}
          value={props?.addressLine1}
          requiredLabel={messages.addressLine1Required}
          onChange={handleInputChange}
        />
        <GaraazInput
          placeholder={messages.addressLine2}
          width="48%"
          label={messages.addressLine2}
          value={props?.addressLine2}
          inputName="addressLine2"
          inputType="text"
          isRequired={false}
          requiredLabel={messages.addressLine2Required}
          onChange={handleInputChange}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        my={{ base: 0, lg: 6 }}
      >
        <GaraazInput
          width="48%"
          label={messages.pinCode}
          placeholder={messages.pinCode}
          value={props?.pinCode}
          inputName="pinCode"
          inputType="number"
          length={{ min: 6, max: 6 }}
          isRequired={true}
          requiredLabel={messages.pinCodeRequired}
          onChange={handleInputChange}
        />
        <Box width={{ base: "100%", lg: "48%" }} mt={{ base: 6, lg: 0 }}>
          <Text fontSize={"xs"} mb="2" color={"gray.500"} fontWeight={"bold"}>
            {messages.state} <span style={{ color: "red" }}>*</span>
          </Text>
          <Select
            value={props?.state}
            placeholder={"Select State"}
            onChange={async (e) => {
              setState(e.target.value);
              setCity("");
              if (props.handleChangeAddressInputs) {
                props.handleChangeAddressInputs("state", e.target.value);
                props.handleChangeAddressInputs("city", "");
              }
            }}
          >
            {data?.states.map((state, index) => {
              return (
                <option
                  selected={props?.state?.toUpperCase() === state.toUpperCase()}
                  value={state}
                  key={`${state}-${index}`}
                >
                  {state}
                </option>
              );
            })}
          </Select>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" mb={6}>
        <Box width={{ base: "100%", lg: "48%" }} mt={{ base: 6, lg: 0 }}>
          <Text fontSize={"xs"} mb="2" color={"gray.500"} fontWeight={"bold"}>
            {messages.city} <span style={{ color: "red" }}>*</span>
          </Text>
          <Select
            value={props?.city}
            placeholder={"Select City"}
            onChange={(e) => {
              setCity(e.target.value);
              if (props.handleChangeAddressInputs) {
                props.handleChangeAddressInputs("city", e.target.value);
              }
            }}
          >
            {cities?.map((city, index) => {
              return (
                <option
                  selected={props?.city?.toUpperCase() === city?.toUpperCase()}
                  value={city}
                  key={`${city}-${index}`}
                >
                  {city}
                </option>
              );
            })}
          </Select>
        </Box>
        <GaraazInput
          width="48%"
          label={messages.country}
          inputName="country"
          placeholder={messages.country}
          value="India"
          isReadOnly={true}
          inputType="text"
          isRequired={false}
        />
      </Box>
    </>
  );
};

export default AddressDetailsFormCustomer;
