import { StyleFunctionProps } from '@chakra-ui/styled-system';

export const InputStyle = {
  baseStyle: (props: StyleFunctionProps) => ({
    field: {
      borderWidth: 1,
      ':focus': {
        borderWidth: 2,
        borderColor: props.theme.colors.tertiary,
      },
    },
  }),

  size: {
    md: {
      padding: { base: 12, lg: 6 },
    },
  },
  variants: {
  },
  defaultProps: {
    size: 'md',
    variant: 'normal',
    colorScheme: (props: StyleFunctionProps) => props.theme.colors.tertiary,
  },
};
