import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useState } from "react";

import breakPoints from "../utils/breakPoints";
import PopupModal from "./PopupModal";
import SelectMonth from "./SelectMonth";
import SelectYear from "./SelectYear";

interface Props {
  title: string;
  selectMonth?: (month: string) => void;
  selectYear?: (year: string) => void;
  popupModalTitle?: string;
  popupModalText?: string;
  icon?: boolean;
  hasButton?: boolean;
  buttonText?: any;
}

const PageHeader = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLargerThan1500] = useMediaQuery(breakPoints.laptopL);
  return (
    <Box position="fixed" width="100%" zIndex="900">
      <Flex
        mb={3}
        alignItems="center"
        justifyContent="space-between"
        p={props.hasButton ? "3" : "4"}
        pl={isLargerThan1500 ? 20 : 10}
        bgColor="primary"
        boxSizing="border-box"
      >
        <HStack w="60%" flex={1}>
          <Text textAlign="left" variant="whiteBoldNormal">
            {props.title}
          </Text>
          {props.icon && (
            <IconButton
              size="30px"
              width="30px"
              height="30px"
              color="#FFFFFF"
              icon={<InfoIcon />}
              aria-label={"Information"}
              onClick={() => setIsOpen(true)}
            />
          )}
          {props.hasButton && <>{props.buttonText}</>}
        </HStack>
        {(props.selectMonth || props.selectYear) && (
          <HStack w="40%" spacing={4}>
            {props.selectMonth && <SelectMonth onSelect={props.selectMonth} />}
            {props.selectYear && <SelectYear onSelect={props.selectYear} />}
          </HStack>
        )}
      </Flex>
      <PopupModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={props.popupModalTitle || ""}
        bodyText={props.popupModalText || ""}
      />
    </Box>
  );
};

export default PageHeader;
