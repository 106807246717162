import { LinkBox, LinkOverlay, Tag, Spinner } from '@chakra-ui/react';
import { FormEvent } from 'react';
import Enum from '../enums';

type Props = {
  active: boolean;
  setActive?: (value: boolean) => void;
  isLoading?: boolean;
};

const ActiveButton = (props: Props) => {
  
  const handleActiveState = (e: FormEvent) => {
    e.preventDefault();

    if (props.setActive) {
      props.setActive(!props.active);
    }
  };

  return (
    <LinkBox>
      {props.isLoading ? (
        <Spinner size='xs' />
      ) : (
        <LinkOverlay href='' onClick={handleActiveState}>
          <Tag colorScheme={props.active ? 'green' : 'gray'}>
            {props.active ? Enum.ACTIVE : Enum.INACTIVE}
          </Tag>
        </LinkOverlay>
      )}
    </LinkBox>
  );
};

export default ActiveButton;
