/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo } from 'react';

import { Box } from '@chakra-ui/react';

import { useGetMobileConfig , useDeleteConfig} from '../queryHooks';

import ModalComponent from '../../../components/ModalComponent';
import SubmitAndCloseButton from '../../../components/SubmitAndCloseButton';
import Enums from '../enums';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedRows: any;
  clearSelectedRows: () => void
};

const DeleteConfigModal = (props: Props) => {
  const { data: mobileGonfigs } = useGetMobileConfig();
  const { mutate: deleteConfig, isLoading: deletingConfig, isSuccess: configDeleted} = useDeleteConfig()



  useEffect(() => {
    if(configDeleted){
      props.onClose();
      props.clearSelectedRows()
    }
  }, [configDeleted])

  const handleSubmit = useCallback(() => {
    if (mobileGonfigs) {
      const indexes = Object.keys(props.selectedRows);
    
      const configKeys = mobileGonfigs!
        .map((config) => {
          return config.key;
        })
        .filter((configKey, index) =>{
          return indexes.some((idx) => parseInt(idx) === index)},
        );

      deleteConfig({data: configKeys })
    }
  }, [deleteConfig, mobileGonfigs, props.selectedRows]);

  const bottomButton = useMemo(() => {
    return (
      <SubmitAndCloseButton
        isDisabled={false}
        isLoading={deletingConfig}
        onSubmit={handleSubmit}
        onClose={props.onClose}
        variant='delete'
      />
    );
  }, [handleSubmit, deletingConfig, props.onClose]);

  return (
    <ModalComponent
      button={bottomButton}
      title={Enums.DELETE_CONFIG_TITLE}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <Box>
        This will delete configuration from the Customer Mobile Apps. Are you
        sure you want to delete?
      </Box>
    </ModalComponent>
  );
};

export default DeleteConfigModal;
