import { Tabs, TabList, Tab, Box } from "@chakra-ui/react";

interface TabBarProps {
  onChange: (index: number) => void;
}

function SubscribeCustomerTab({ onChange }: TabBarProps): JSX.Element {
  function handleTabClick(index: number) {
    onChange(index);
  }

  return (
    <Box>
      <Tabs isFitted onChange={(index) => handleTabClick(index)}>
        <TabList mb="1em">
          <Tab>Ongoing</Tab>
          <Tab>Expired</Tab>
          <Tab>Expiring Soon</Tab>
          <Tab>Upcoming</Tab>
        </TabList>
      </Tabs>
    </Box>
  );
}

export default SubscribeCustomerTab;
