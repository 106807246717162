import { Flex, Spinner, Text } from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import { pageTitles } from "../../utils/paths";
import DashboardCard from "../../components/DashboardCard";
import { useDailyUrlDashboard } from "./queryHooks";


const Index = () => {
  const { data: liveDashboard, isLoading: loading } = useDailyUrlDashboard();
    
  let getDashboardUrlData = [];
  try {
    if (liveDashboard && liveDashboard.dashboards && !loading) {
      getDashboardUrlData = JSON.parse(liveDashboard.dashboards);
    } else {
      getDashboardUrlData = [];
    }
  } catch (error) {
    getDashboardUrlData = [];
  }

  return (
    <PageScaffold title={pageTitles.dashboards}>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" minH="100vh">
          <Spinner size="xl" color="blue.500" />
        </Flex>
       ) : (
        <Flex flexWrap="wrap" gap={6} >

          {getDashboardUrlData?.length === 0 ? (
            <Text textAlign="center" w="100%">
              No data available today
            </Text>
          ) : (
            
            getDashboardUrlData?.map((dashboard: any, idx: number) => (
              
              <DashboardCard
                key={String(idx)}
                title={dashboard?.title}
                value={dashboard?.url}
              />
            ))
          )}
        </Flex>
      )}
    </PageScaffold>
  );
};

export default Index;
