import { useQuery } from "@tanstack/react-query";
import Services from './services';
import Enums from './Enums'


export const useGetMonthlyMetrics = (selectedMonth: string, selectedYear: string) => {
    return useQuery(
        [Enums.MONTHLY_METRICS, selectedMonth, selectedYear],
        async () => {
            const response = await Services.getMonthlyCustomerMetrics(selectedMonth, selectedYear);
            return response.data;
        },
        {
            enabled: selectedMonth !== "" && selectedYear !== ""
        }
    );
}