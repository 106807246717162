import { Box, Flex, Spinner } from "@chakra-ui/react";
import SubscribeCustomerTab from "../../components/SubscribeCustomerTab";
import PageScaffold from "../../components/PageScaffold";
import { pageTitles } from "../../utils/paths";

import { useState } from "react";
import GaraazTable, { COLUMN_TYPES } from "../../components/GaraazTable";

const Index = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading] = useState(false);
  const [isError] = useState(false);

  const TableHeading = [
    { header: "Name", accessor: "name", type: COLUMN_TYPES.NAME },
    {
      header: "Mobile Number",
      accessor: "mobileNumber",
      type: COLUMN_TYPES.MOBILE,
    },
    {
      header: "Start Date",
      accessor: "subscriptions[0].startDate",
      type: COLUMN_TYPES.DATE,
    },
    {
      header: "End Date",
      accessor: "subscriptions[0].endDate",
      type: COLUMN_TYPES.DATE,
    },
    {
      header: "Delivery Plan",
      accessor: "subscriptions[0].plan_deliveries",
      type: COLUMN_TYPES.VALUE,
    },
    {
      header: "Taken Deliveries",
      accessor: "subscriptions[0].taken_deliveries",
    },
    {
      header: "Amount",
      accessor: "subscriptions[0].amount",
      type: COLUMN_TYPES.AMOUNT,
    },
    {
      header: "Invoice",
      accessor: "subscriptions[0].zoho_invoice_url",
      type: COLUMN_TYPES.INVOICE,
    },
  ];

  const row = [10, 20, 30, 50, 100, 150];
  const value = 50;

  return (
    <PageScaffold title={pageTitles.subscriptions}>
      {isLoading && (
        <Flex justifyContent="center" alignItems="center" minH="100vh">
          <Spinner size="xl" color="blue.500" />
        </Flex>
      )}
      {isError && <Box>There was an error loading the data.</Box>}

      <Box>
        <SubscribeCustomerTab onChange={setActiveTab} />
      </Box>

      {activeTab === 0 && (
        <GaraazTable
          columns={TableHeading}
          url={`${process.env.REACT_APP_BASE_URL}/v1/customers?subscriptions=active`}
          limits={row}
          defaultRowLimit={value}
        />
      )}
      {activeTab === 1 && (
        <GaraazTable
          columns={TableHeading}
          url={`${process.env.REACT_APP_BASE_URL}/v1/customers?subscriptions=inactive`}
          limits={row}
          defaultRowLimit={value}
        />
      )}
      {activeTab === 2 && (
        <GaraazTable
          columns={TableHeading}
          url={`${process.env.REACT_APP_BASE_URL}/v1/customers?subscriptions=expiring_soon`}
          limits={row}
          defaultRowLimit={value}
        />
      )}
      {activeTab === 3 && (
        <GaraazTable
          columns={TableHeading}
          url={`${process.env.REACT_APP_BASE_URL}/v1/customers?subscriptions=upcoming`}
          limits={row}
          defaultRowLimit={value}
        />
      )}
    </PageScaffold>
  );
};

export default Index;
