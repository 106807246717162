import React, { useEffect, useRef, useState } from "react";
import { secondaryColors } from "../../../utils/designSystem";
import {
  Flex,
  FormControl,
  Button,
  Switch,
  Box,
  Text,
  useDisclosure,
  Input,
  Select as Select1,
} from "@chakra-ui/react";

import Select from "react-select";

import GaraazAlertDialog from "../../../components/GaraazAlertDialog";
import GaraazFormLabel from "../../../components/GarrazFormLabel";
import { GrazeonFormData } from "./AddGrazeons";

type RolesFormProps = {
  index: number;
  formData: GrazeonFormData;
  setFormData: React.Dispatch<React.SetStateAction<GrazeonFormData>>;
  handleRolesFieldBlur: (key: string, index: number) => void;
  componentRolesObject: {
    [key: string]: any;
  };
  setComponentRolesObject: React.Dispatch<
    React.SetStateAction<{
      [key: string]: any;
    }>
  >;
  isRolesFieldTouched: (value: string, index: number) => any;
  handleDeleteRoles: (index: number) => void;
  isEditOne?: boolean;
};

const RolesForm: React.FC<RolesFormProps> = ({
  index,
  formData,
  setFormData,
  handleRolesFieldBlur,
  componentRolesObject,
  isRolesFieldTouched,
  handleDeleteRoles,
  isEditOne,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const ComponentOptions: any = Object.keys(componentRolesObject)?.map(
    (item) => item
  );

  return (
    <Box
      key={index}
      bgColor={secondaryColors.secondary_100}
      padding={4}
      width={"100%"}
      mb={4}
    >
      <Flex width={"70%"} mb={4}>
        <FormControl width={"25%"}>
          <GaraazFormLabel text="Component" isRequired={true}></GaraazFormLabel>
        </FormControl>
        <Flex width={"75%"}>
          <FormControl width={"50%"}>
            <Select
              placeholder="Select Component"
              value={{
                value: formData.roles[index].component,
                label: formData.roles[index].component,
              }}
              options={ComponentOptions?.map((item: any) => {
                let hasCount = 0;
                componentRolesObject[item].forEach((allowedRoles: any) => {
                  if (
                    formData.roles.some(
                      (rolesInForm) =>
                        rolesInForm.component === item &&
                        rolesInForm.role === allowedRoles
                    )
                  ) {
                    hasCount++;
                  }
                });
                return {
                  value: item,
                  label: item,
                  isDisabled: hasCount === componentRolesObject[item].length,
                };
              })}
              onChange={(e: any) => {
                setFormData((prevFormData) => {
                  let newRoles = prevFormData.roles;
                  newRoles[index].component = e.value;
                  newRoles[index].role = "";

                  return {
                    ...prevFormData,
                    roles: newRoles,
                  };
                });
              }}
              onBlur={() => {
                handleRolesFieldBlur("component", index);
              }}
            ></Select>
            {isRolesFieldTouched("component", index) &&
              formData.roles[index].component === "" && (
                <Text color="red" fontSize={"sm"}>
                  This field is required
                </Text>
              )}
          </FormControl>
        </Flex>
      </Flex>
      <Flex width={"70%"} mb={4}>
        <FormControl width={"25%"}>
          <GaraazFormLabel text="Role" isRequired={true}></GaraazFormLabel>
        </FormControl>
        <Flex width={"75%"}>
          <FormControl width={"50%"}>
            <Select
              options={
                formData.roles[index].component !== ""
                  ? componentRolesObject[formData.roles[index].component]?.map(
                      (item: any) => ({
                        value: item,
                        label: item,
                        isDisabled: formData.roles.some(
                          (formRoles) =>
                            formRoles.role === item &&
                            formRoles.component ===
                              formData.roles[index].component
                        ),
                      })
                    )
                  : []
              }
              placeholder="Select Role"
              value={{
                value: formData.roles[index].role,
                label: formData.roles[index].role,
              }}
              onChange={(e: any) => {
                setFormData((prevFormData) => {
                  let newRoles = prevFormData.roles;
                  newRoles[index].role = e.value;
                  return {
                    ...prevFormData,
                    roles: newRoles,
                  };
                });
              }}
              onBlur={() => {
                handleRolesFieldBlur("role", index);
              }}
            ></Select>
            {isRolesFieldTouched("role", index) &&
              formData.roles[index].role === "" && (
                <Text color="red" fontSize={"sm"}>
                  This field is required
                </Text>
              )}
          </FormControl>
        </Flex>
      </Flex>
      <Flex mb={4} width={"70%"}>
        <FormControl width={"25%"}>
          <GaraazFormLabel
            text="isVerified"
            isRequired={true}
          ></GaraazFormLabel>
        </FormControl>
        <Flex>
          <FormControl>
            <Switch
              size={"lg"}
              isChecked={formData.roles[index].isVerified}
              onChange={(e) => {
                setFormData((prevFormData) => {
                  let newRoles = prevFormData.roles;
                  newRoles[index].isVerified = e.target.checked;
                  return {
                    ...prevFormData,
                    roles: newRoles,
                  };
                });
              }}
            ></Switch>
          </FormControl>
        </Flex>
      </Flex>
      <Button
        display={"block"}
        onClick={() => {
          if (isEditOne) {
            onOpen();
          } else {
            handleDeleteRoles(index);
          }
        }}
        ml={"auto"}
        color={"red.400"}
        size={"sm"}
        bg={"none"}
        _hover={{ backgroundColor: "#e9ecef !important" }}
        _focus={{ bg: "none", boxShadow: "none" }}
      >
        Delete
      </Button>
      <GaraazAlertDialog
        onClose={onClose}
        isOpen={isOpen}
        bodyText="Are you sure to delete this role"
        headerText="Delete Role"
        branchName={`${formData.roles[index].component}`}
        onSubmitButtonClick={() => {
          handleDeleteRoles(index);
          onClose();
        }}
        submitBtnText="Delete"
        key={index}
      ></GaraazAlertDialog>
    </Box>
  );
};

export default RolesForm;
