import { useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import PhoneNumberForm from './components/PhoneNumberForm';
import OtpForm from './components/OtpForm';
import globalConstant from '../../utils/constants'
import AuthViewLayout from '../../components/AuthViewLayout';
import { showOtpPageType } from './types';
import { LocationState } from '../../utils/types'

export function Index() {
  const location = useLocation();
  const [showOtpPageProps, setShowOtpPage] = useState({ showOtpPage: false, phoneNumber: '' });

  let from = (location.state as LocationState)
    ? (location.state as LocationState).from!.pathname
    : '/';
  if (localStorage.getItem(globalConstant.MC_SUPER_ADMIN)) {
    return <Navigate to={from} state={{ from: location }} replace />;
  }


  const handleShowOtpPage = (value: showOtpPageType) => {
    setShowOtpPage(value);
  }


  return (
    <AuthViewLayout>
      <>
        {showOtpPageProps.showOtpPage && <OtpForm setShowOtpPage={handleShowOtpPage} phoneNumber={showOtpPageProps.phoneNumber} />}
        {!showOtpPageProps.showOtpPage && (
          <PhoneNumberForm
            setShowOtpPage={handleShowOtpPage}
          />
        )}
      </>
    </AuthViewLayout>
  );
};

export default Index;
