enum Enum {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    LOW = 'Low',
    HIGH = 'High',
    MEDIUM = 'Medium',
    CREATE_BANNER_SUCCESS_MESSAGE = 'Banner added successfully',
    NO_DUPLICATE_PRIORITY = 'No duplicate priority',
    PRIOTITY_ERROR = 'Priority already exist',
    DELETE_BANNER_SUCCESS_MESSAGE = 'Banner was successfully deleted!',
    GET_BANNER_KEY = 'banners',
    MEDIA = 'media',
    PRIORITY = 'priority',
    ACTIVE_STATE = 'active',
    DISTRIBUTOR_ID = 'distributorId',
    GET = 'getBanners',
    ADD_BANNER='Add Banner',
    DELETE_BANNER='Delete Banner'
}

export default Enum;