export const getBrandsNameSorted = (arr: any[]) => {
  const customOrder =
    "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz0123456789";

  arr.sort((a, b) => {
    const nameA = a.name.charAt(0);
    const nameB = b.name.charAt(0);

    const indexA = customOrder.indexOf(nameA);
    const indexB = customOrder.indexOf(nameB);

    return indexA - indexB;
  });

  return arr;
};
