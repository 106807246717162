import React, { FC } from "react";
import { FormLabel, FormLabelProps } from "@chakra-ui/react";

interface GaraazFormLabelProps extends FormLabelProps {
  text: string;
  isRequired: boolean;
}

const GaraazFormLabel: FC<GaraazFormLabelProps> = ({
  text,
  isRequired,
  ...rest
}) => {
  return (
    <FormLabel
      fontSize={"xs"}
      fontWeight={"bold"}
      textTransform={"uppercase"}
      className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
      {...rest}
    >
      {text}
      {isRequired && (
        <span style={{ color: "red" }} className="text-youtube-regular">
          *
        </span>
      )}
    </FormLabel>
  );
};

export default GaraazFormLabel;
