/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormEvent,
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import { Center } from '@chakra-ui/react';
import { FiUploadCloud } from 'react-icons/fi';

import MediaComponent from '../../../components/MediaComponent';
import CardScaffold from '../../../components/CardScaffold';
import SubmitAndCloseButton from '../../../components/SubmitAndCloseButton';
import ModalComponent from '../../../components/ModalComponent';
import Clickable from '../../../components/Clickable';
import FileInput from '../../../components/FileInput';
import Enums from '../Enums';
import globalConstant from '../../../utils/constants';

import { useCreateAdvert } from '../queryHooks';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

const CreateAdvert = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [path, setPath] = useState('');
  const [file, setFile] = useState<Blob>();
  const {
    mutate: createAdvert,
    isLoading: creatingAdvert,
    isSuccess,
  } = useCreateAdvert();

  const handleClose = () => {
    setFile(undefined);
    setPath('');
    props.onClose();
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  const handleImportFile = () => {
    inputRef?.current?.click();
  };

  const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    const file = e.currentTarget?.files![0];
    const path = URL.createObjectURL(file);
    setFile(file);
    setPath(path);
  };

  const handleCreateAdvert = useCallback(() => {
    if (file) {
      const formData = new FormData();
      formData.append(globalConstant.MEDIA, file);

      createAdvert({ data: formData });
    }
  }, [file]);

  const bottomButton = useMemo(() => {
    return (
      <SubmitAndCloseButton
        isDisabled={!Boolean(file)}
        isLoading={creatingAdvert}
        onSubmit={handleCreateAdvert}
        onClose={handleClose}
      />
    );
  }, [handleCreateAdvert, handleClose, creatingAdvert]);

  return (
    <ModalComponent
      button={bottomButton}
      isOpen={props.isOpen}
      title={Enums.ADD_ADVERTS}
      onClose={handleClose}
    >
      <CardScaffold
        width='100%'
        media={
          Boolean(path) ? (
            <Clickable onClick={handleImportFile}>
              <MediaComponent
                path={path}
                isVideo={file?.type.startsWith('video') ?? false}
              />
            </Clickable>
          ) : (
            <Clickable onClick={handleImportFile}>
              <Center height='100%' fontSize='5vw'>
                <FiUploadCloud />
              </Center>
            </Clickable>
          )
        }
      />
      <FileInput ref={inputRef} onChange={handleInputChange} />
    </ModalComponent>
  );
};
export default CreateAdvert;
