import { useEffect, SetStateAction, Dispatch } from 'react'
type Props = {
    setTimeOut: (count: number) => void,
    time: Record<string, number>,
    setTime: Dispatch<SetStateAction<{initialMinute: number,initialSeconds: number }>>,
}


export const Timer = ({ setTimeOut, time = {}, setTime } : Props) => {
    
  const { initialMinute: minutes, initialSeconds: seconds } = time
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setTime({ initialMinute: minutes, initialSeconds: seconds - 1 });
      }
      if (seconds === 0) {
        if (minutes === 0) {
          setTimeOut(1)
          clearInterval(myInterval)
        } else {
          setTime({ initialMinute: minutes - 1, initialSeconds: 59 });
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      {minutes === 0 && seconds === 0
        ? null
        : <h1> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h1>
      }
    </div>
  )
}

export default Timer;