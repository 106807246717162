// @flow
import * as React from "react";
import {
  Box,
  Image,
  Center,
  VStack,
  Link,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { pathObject } from "../utils/paths";
import { FiLogOut } from "react-icons/fi";
import { SubNav } from "../utils/types";
import getUserRole from "../utils/getUserRole";
import { useUserDetailsContext } from "../Contexts/userDetailsContext";

const Index = () => {
  const userDetails = useUserDetailsContext();
  const navs = pathObject
    .filter((item) =>
      item.rolesAllowed.includes(getUserRole(userDetails) as any)
    )
    .reduce((prevVal: SubNav[], currVal) => {
      return [...prevVal, ...currVal.sub];
    }, [])
    .map((item) => <NavIcon {...item} key={item.path} />);

  return (
    <VStack
      bg="white"
      width="100%"
      height="100%"
      borderRight="1px solid"
      borderColor="gray.200"
    >
      <VStack borderBottom="1px solid" borderColor="gray.200" w="100%" h="20%">
        <Center h="100%">
          <Image src="/images/logo.png" boxSize="5vw" />
        </Center>
      </VStack>
      <Box
        display="flex"
        flexDirection="column"
        height="78%"
        width="100%"
        pt="5"
        overflow={"scroll"}
      >
        <>{navs}</>
        <LogoutIcon />
      </Box>
    </VStack>
  );
};

const NavIcon = ({
  path,
  icon,
  name,
}: {
  path: string;
  icon: React.ReactNode;
  name: string;
}) => {
  return (
    <Center>
      <Tooltip label={name}>
        <Link
          as={NavLink}
          to={path}
          style={{ display: "grid", placeItems: "center" }}
        >
          <IconButton variant="solid" aria-label={""}>
            {icon}
          </IconButton>
        </Link>
      </Tooltip>
    </Center>
  );
};

const LogoutIcon = () => {
  return (
    <Center mt="auto">
      <Tooltip label="Logout">
        <IconButton color="secondary" variant="solid" aria-label={""}>
          <FiLogOut />
        </IconButton>
      </Tooltip>
    </Center>
  );
};

export default Index;
