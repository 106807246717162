import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export function dateFormatDDMMYYYY(date: any) {
  if (!date) return "";
  const formats = ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY"];
  let formattedDate = null;
  for (let i = 0; i < formats.length; i++) {
    const parsedDate = dayjs(date, formats[i]);
    if (parsedDate.isValid()) {
      const dayOfMonth = parsedDate.date();
      const month = parsedDate.format("MMM");
      const year = parsedDate.format("YY");
      const dayOfMonthString =
        dayOfMonth +
        (dayOfMonth % 10 === 1 && dayOfMonth !== 11
          ? "st"
          : dayOfMonth % 10 === 2 && dayOfMonth !== 12
          ? "nd"
          : dayOfMonth % 10 === 3 && dayOfMonth !== 13
          ? "rd"
          : "th");
      formattedDate = `${dayOfMonthString} ${month} ${year}`;
      break;
    }
  }

  return formattedDate || "";

}
