import { Box } from '@chakra-ui/react';


const VideoComponent = (props: {path: string}) => {
  return (
    <Box borderTopRadius='md'  overflow='hidden'>
      <video controls>
        <source   src={props.path} type='video/mp4' />
        <source src={props.path} type='video/webm' />
      </video>
    </Box>
  );
};

export default VideoComponent;
