import {
  Box,
  ListItem,
  UnorderedList,
  Image,
  Text,
  Checkbox,
  IconButton,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import services from "../services";
import PageScaffold from "../../../components/PageScaffold";
import paths, { pageTitles } from "../../../utils/paths";
import { BiSave } from "react-icons/bi";
import useNotification from "../../../utils/useNotification";
import AddNewSparePart from "./AddNewSparePart";
import GaraazGeneralTable from "../../../components/GaraazGeneralTable";

const EditCompatibility = () => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const toast = useNotification();
  const [partData, setPartData] = useState<any>(null);
  const [initialCompatibility, setInitialCompatibility] = useState<any>([]);
  const [fetchingCars, setFetchingCars] = useState(false);
  const [currentCompatibility, setCurrentCompatibility] = useState<any>([]);
  const [generationWiseCars, setGenerationWiseCars] = useState({});
  const [isCompatibilityVisible, setIsCompatibilityVisible] = useState(false);
  const UPNSelectRef = useRef<any>(null);
  const partNumberInputRef = useRef<any>(null);

  useEffect(() => {
    if (state.sparePartId) {
      fetchSparePartData();
    }
    fetchCarsData();
  }, [state]);

  async function fetchSparePartData() {
    setIsCompatibilityVisible(true);
    const response = await services.getSparePartById(state.sparePartId);
    setPartData(response);
    setCurrentCompatibility(response?.compatibility);
    setInitialCompatibility(response?.compatibility);
  }

  async function fetchCarsData() {
    setFetchingCars(true);
    const response = await services.fetchCars();
    setFetchingCars(false);
    const groups = response.reduce((acc: any, obj: any) => {
      if (!acc[obj.generation]) {
        acc[obj.generation] = [];
      }
      acc[obj.generation].push(obj);
      return acc;
    }, {});

    setGenerationWiseCars(groups);
  }

  async function handleCompatiblityChange(
    e: React.ChangeEvent<HTMLInputElement>,
    car: any
  ) {
    if (e.target.checked) {
      setCurrentCompatibility((prev: any) => {
        const newCurrentCompatiblity = [...prev];
        newCurrentCompatiblity.push(car);
        return newCurrentCompatiblity;
      });
    } else {
      setCurrentCompatibility((prev: any) => {
        const newCurrentCompatiblity = [...prev];
        const index = currentCompatibility.findIndex(
          (elem: any) => elem._id === car._id
        );
        if (index > -1) {
          newCurrentCompatiblity.splice(index, 1);
        }
        return newCurrentCompatiblity;
      });
    }
  }

  async function handleSaveCompatibility() {
    try {
      const initialCompatibleCarIds = new Set(
        initialCompatibility.map((car: any) => car._id)
      );
      const currentCompatibleCarIds = new Set(
        currentCompatibility.map((car: any) => car._id)
      );

      const carsToAdd = Array.from(currentCompatibleCarIds).filter(
        (carId) => !initialCompatibleCarIds.has(carId)
      );
      const carsToRemove = Array.from(initialCompatibleCarIds).filter(
        (carId) => !currentCompatibleCarIds.has(carId)
      );

      if (
        (carsToAdd.length === 0 && carsToRemove.length === 0) ||
        UPNSelectRef.current.value === "" ||
        partNumberInputRef.current.value.trim() === ""
      ) {
        toast({
          description:
            "Compatibility should be updated and UPN and Part Number should not be empty",
          status: "error",
        });
        return;
      }

      await services.postSparePartCompatibilityRequest({
        name: "UPSERT_SPARE_PART_REQUEST",
        UPN: partData?.UPN ?? UPNSelectRef.current.value,
        partNumber: partData?.number ?? partNumberInputRef.current.value,
        addCars: carsToAdd,
        removeCars: carsToRemove,
      });

      toast({
        status: "success",
        description: "Spare Part Compatibility Request Submitted",
      });

      setCurrentCompatibility(initialCompatibility);
      navigate(`/${paths.sparePart}`);
    } catch (error: any) {
      toast({ description: error.response.data.errorMsg, status: "error" });
    }
  }

  async function handleUPNAndPNSearchFormmSubmit(
    e: React.FormEvent<HTMLFormElement>
  ) {
    e.preventDefault();
    if (
      partNumberInputRef.current.value.trim() === "" ||
      UPNSelectRef.current.value.trim() === ""
    )
      return;

    const response = await services.getSparePartByQuery(
      `?UPN=${UPNSelectRef.current.value}&number=${partNumberInputRef.current.value}&compatibilityPopulated=true`
    );
    if (response.data[0]) {
      setPartData(response.data[0]);
      setCurrentCompatibility(response.data[0]?.compatibility);
      setInitialCompatibility(response.data[0]?.compatibility);
    }
    setIsCompatibilityVisible(true);
  }

  return (
    <PageScaffold
      title={
        state.sparePartId ? pageTitles.EditSparePart : pageTitles.addSparePart
      }
      hasButton={true}
      buttonText={
        <IconButton
          position={"fixed"}
          right={4}
          aria-label="saveBtn"
          size="md"
          color={"white"}
          icon={<BiSave size={"1.5rem"} />}
          onClick={handleSaveCompatibility}
          isDisabled={!isCompatibilityVisible}
        />
      }
    >
      <form onSubmit={handleUPNAndPNSearchFormmSubmit}>
        {(partData || !state.sparePartId) && (
          <AddNewSparePart
            UPNSelectRef={UPNSelectRef}
            partData={partData}
            partNumberInputRef={partNumberInputRef}
            isCompatibilityVisible={isCompatibilityVisible}
          ></AddNewSparePart>
        )}
        <Button type="submit" isDisabled={isCompatibilityVisible}>
          Add
        </Button>
      </form>

      {fetchingCars && <Spinner mt={10}></Spinner>}
      {isCompatibilityVisible && (
        <>
          <Text fontSize={"xl"} fontWeight={"bold"} mt={10}>
            Compatibility
          </Text>
          <UnorderedList listStyleType={"none"} mt={10} ml={0}>
            {Object.entries(generationWiseCars).map(
              ([generation, cars]: [any, any]) => {
                let columns = [
                  {
                    header: "Comp",
                    renderCell: (row: any) => {
                      return (
                        <Checkbox
                          isChecked={currentCompatibility.some(
                            (comp: any) => comp._id === row._id
                          )}
                          onChange={(e) => {
                            handleCompatiblityChange(e, row);
                          }}
                        ></Checkbox>
                      );
                    },
                  },
                  {
                    header: "Name",
                    accessor: "name",
                  },
                  {
                    header: "Region",
                    value: "INDIA",
                  },
                  {
                    header: "Production Year",
                    accessor: "productionYear",
                  },
                  {
                    header: "Engine",
                    accessor: "engine",
                  },
                  {
                    header: "Description",
                    accessor: "description",
                  },
                  {
                    header: "Chassis Type",
                    accessor: "chassisType",
                  },
                  {
                    header: "V No.",
                    accessor: "vNo",
                  },
                ];
                return (
                  <ListItem
                    mt={5}
                    borderBottom={"1px solid lightgrey"}
                    pb={5}
                    key={generation}
                  >
                    <Box
                      display={"flex"}
                      width={"100%"}
                      flexDirection={"column"}
                    >
                      <Box width={"100%"} p={2}>
                        <Image src={cars[0].preview}></Image>
                        <Text fontWeight={"extrabold"}>{generation}</Text>
                      </Box>
                      <Box width={"100%"}>
                        <GaraazGeneralTable
                          data={cars}
                          columns={columns}
                        ></GaraazGeneralTable>
                      </Box>
                    </Box>
                  </ListItem>
                );
              }
            )}
          </UnorderedList>
        </>
      )}
    </PageScaffold>
  );
};

export default EditCompatibility;
