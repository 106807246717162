import { useState } from 'react';
import {
  Center,
  Text,
  VStack,
  Button,
  HStack,
  PinInputField,
  PinInput,
} from '@chakra-ui/react';


import Timer from './Timer';
import { useVerifyOTP, useGetOTP } from '../queryHooks';
import { useVisit } from './VisitContext';
import { showOtpPageType } from '../types';
import Enums from '../enums'

type Props = {
  phoneNumber: string;
  setShowOtpPage: (value: showOtpPageType) => void;
};

function  OtpForm(props: Props){
  const visitId = useVisit();
  const [OTP, setOTP] = useState('');
  const [timeOut, setTimeOut] = useState(0);
  const [time, setTime] = useState({ initialMinute: 0, initialSeconds: 15 });
  const { mutate: verifyOTP, isLoading: isVerifying } = useVerifyOTP();
  const { mutate: getOTP } = useGetOTP();

  const updateOTP = (otp: string) => {
    setOTP(otp);
  };

  const handleOtpSubmit = () => {
    verifyOTP({ OTP, phoneNumber: props.phoneNumber, mobileNumberPrefix: Enums.MOBILE_NUMBER_PREFIX });
  };

  const handleOtpResend = () => {
    getOTP({ phoneNumber: props.phoneNumber, visitId, mobileNumberPrefix: Enums.MOBILE_NUMBER_PREFIX });
    setTimeOut(0);
    setTime({ initialMinute: 0, initialSeconds: 15 });
  };

  const handleChangeNumber = () => {
    props.setShowOtpPage({ showOtpPage: false, phoneNumber: '' });
  };

  return (
    <VStack>
      <Text variant='mutedCenter'>
        OTP sent successfully on: {props.phoneNumber}
      </Text>
      <Text variant='mutedCenter'>Enter the OTP to sign-in</Text>
      <Center>
        <HStack>
          <PinInput size='lg' autoFocus onComplete={updateOTP}>
            <PinInputField bgColor='white' />
            <PinInputField bgColor='white' />
            <PinInputField bgColor='white' />
            <PinInputField bgColor='white' />
          </PinInput>
        </HStack>
      </Center>
      <Center>
        <Button fontWeight='normal' variant='noBg' onClick={handleChangeNumber}>
          Change Mobile Number
        </Button>
      </Center>
      {Boolean(timeOut) ? (
        <Center>
          <Button variant='noBg' onClick={handleOtpResend}>
            Resend OTP
          </Button>
        </Center>
      ) : (
        <Timer setTimeOut={setTimeOut} time={time} setTime={setTime} />
      )}
      <Center>
        <Button isLoading={isVerifying} onClick={handleOtpSubmit}>
          Sign In
        </Button>
      </Center>
    </VStack>
  );
};

export default OtpForm;
