import React, { ChangeEvent } from "react";
import GaraazInput from "../../../components/GaraazInput";
import {
  Heading,
  Flex,
  FormControl,
  InputRightAddon,
  Stack,
  Box,
} from "@chakra-ui/react";
import GaraazFormLabel from "../../../components/GarrazFormLabel";
import { supplierFormData } from "./AddSupplier";
import { secondaryColors } from "../../../utils/designSystem";

type BusinessTermsProps = {
  formData: supplierFormData;
  setFormData: React.Dispatch<React.SetStateAction<supplierFormData>>;
};

const CodTerms: React.FC<BusinessTermsProps> = ({ formData, setFormData }) => {
  const handleCodTermsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      businessTerms: {
        ...prevFormData.businessTerms,
        codTerms: {
          ...prevFormData.businessTerms?.codTerms,
          [name]: value,
        },
      },
    }));
  };

  return (
    <Stack mt="8">
      <Heading mb="1" as="h5" size="md">
        COD Terms
      </Heading>
      <Box
        borderWidth={1}
        borderRadius={"md"}
        p="4"
        style={{
          marginLeft: "1.5rem",
        }}
        bg={`${secondaryColors.secondary_100}`}
      >
        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl pt="1.5" width={"25%"} flex={1}>
            <GaraazFormLabel text="Cod discount" isRequired={false} />
          </FormControl>
          <GaraazInput
            width="75%"
            inputName="codDiscount"
            inputType="number"
            placeholder=""
            isRequired={false}
            inputRightAddOn={
              <InputRightAddon
                borderWidth={1}
                children="%"
                bg="whiteAlpha.900"
              />
            }
            value={formData.businessTerms?.codTerms.codDiscount}
            onChange={handleCodTermsChange}
            errorMsg={
              Number(formData.businessTerms?.codTerms.codDiscount) > 100 ||
              Number(formData.businessTerms?.codTerms.codDiscount) < 0
                ? "Please add valid %"
                : ""
            }
          />
        </Flex>
      </Box>
    </Stack>
  );
};

export default CodTerms;
