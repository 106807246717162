import { Box, Flex, FormControl, Input, Select } from "@chakra-ui/react";
import GaraazFormLabel from "../../../components/GarrazFormLabel";
import { useEffect, useState } from "react";
import services from "../services";
import useNotification from "../../../utils/useNotification";

type AddNewSparePartProps = {
  carData: any;
  generationSelectRef: React.MutableRefObject<any>;
  nameRef: React.MutableRefObject<any>;
  descriptionRef: React.MutableRefObject<any>;
  isCompatibilityVisible: boolean;
};

const AddNewCar: React.FC<AddNewSparePartProps> = ({
  carData,
  generationSelectRef,
  nameRef,
  isCompatibilityVisible,
  descriptionRef,
}) => {
  const [selectedGeneration, setSelectedGeneration] = useState(
    carData?.generation
  );
  const [selectedName, setSelectedName] = useState(carData?.name);
  const [selectedDescription, setSelectedDescription] = useState(
    carData?.description
  );
  const [uniqueGenerations, setUniqueGenerations] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const response = await services.getUniqueCarGenerations();
    setUniqueGenerations(response);
  }

  return (
    <Box>
      <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
        <FormControl width={"25%"} id="Generatiom" flex={1}>
          <GaraazFormLabel text="Generation" isRequired={true} />
        </FormControl>
        <Flex width={"75%"}>
          <FormControl flex={1}>
            <Select
              ref={generationSelectRef}
              placeholder={"Select Generation"}
              name="Generation"
              value={selectedGeneration}
              onChange={(e) => {
                setSelectedGeneration(e.target.value);
              }}
              isDisabled={isCompatibilityVisible}
            >
              {uniqueGenerations.map((Generation: any, index: any) => (
                <option value={Generation} key={`${Generation}-${index}`}>
                  {Generation}
                </option>
              ))}
            </Select>
          </FormControl>
        </Flex>
      </Flex>
      <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
        <FormControl width={"25%"} id="Name" flex={1}>
          <GaraazFormLabel text="Name" isRequired={true} />
        </FormControl>
        <Flex width={"75%"}>
          <FormControl flex={1}>
            <Input
              ref={nameRef}
              type="text"
              placeholder="Enter Name"
              name="name"
              value={selectedName}
              onChange={(e) => {
                setSelectedName(e.target.value);
              }}
              isDisabled={isCompatibilityVisible}
            />
          </FormControl>
        </Flex>
      </Flex>
      <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
        <FormControl width={"25%"} id="Description" flex={1}>
          <GaraazFormLabel text="Description" isRequired={true} />
        </FormControl>
        <Flex width={"75%"}>
          <FormControl flex={1}>
            <Input
              ref={descriptionRef}
              type="text"
              placeholder="Enter Description"
              name="description"
              value={selectedDescription}
              onChange={(e) => {
                setSelectedDescription(e.target.value);
              }}
              isDisabled={isCompatibilityVisible}
            />
          </FormControl>
        </Flex>
      </Flex>
    </Box>
  );
};

export default AddNewCar;
