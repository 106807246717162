import endpoints from '../../utils/endpoints';
import { authAxios as axios } from '../../config/axios';
import globalConstant from '../../utils/constants';
import { Media } from './types';

class Services {
    async createBanner(payload: {data: FormData}) {
        const response = await axios({
            method: globalConstant.POST,
            url: endpoints.banners,
            data: payload.data,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        return response.data
    }

    async getBanners() {
        const response = await axios({
            method: globalConstant.GET,
            url: endpoints.banners
        })
        return response.data
    }

    async deleteBanners(payload: { bannerId: string }) {
        const response = await axios({
            method: globalConstant.DELETE,
            url: `${endpoints.banners}/${payload.bannerId}`,
        })
        return response.data
    }

    async updateBanners(payload: {
        media: Media,
        bannerId: string, data: {
            priority?: number,
            active?: boolean
        }
    }) {
        const response = await axios({
            method: globalConstant.PATCH,
            url: `${endpoints.banners}/${payload.bannerId}`,
            data: payload.data
        })
        return response.data
    }

}

export default new Services()