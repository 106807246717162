import React, { useEffect, useState } from "react";
import GaraazInput from "../../../components/GaraazInput";
import {
  Box,
  Select,
  Stack,
  Button,
  InputRightAddon,
  InputLeftAddon,
  useDisclosure,
  Text,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import GaraazFormLabel from "../../../components/GarrazFormLabel";
import { secondaryColors } from "../../../utils/designSystem";
import { supplierFormData } from "./AddSupplier";
import GaraazAlertDialog from "../../../components/GaraazAlertDialog";
import globalConstant from "../../../utils/constants";
import useNotification from "../../../utils/useNotification";
import { useGetBrandsInfo, useGetPartCategoryInfo } from "../queryHooks";

export interface CategoryOption {
  _id: string;
  category: string;
}

interface BrandOption {
  _id: string;
  name: string;
}

export type DiscountTermsProps = {
  formData: supplierFormData;
  setFormData: React.Dispatch<React.SetStateAction<supplierFormData>>;
  index: number;
};

const DiscountTerms: React.FC<DiscountTermsProps> = ({
  formData,
  setFormData,
  index,
}) => {
  const { data: allBrands, isSuccess: isSuccessGetBrandsInfo } =
    useGetBrandsInfo();
  const { data: allPartCategories, isSuccess: isSuccessGetPartInfo } =
    useGetPartCategoryInfo();

  const [brands, setBrands] = useState<BrandOption[]>([]);
  const [categories, setCategories] = useState<CategoryOption[]>([]);
  const toast = useNotification();

  const {
    isOpen: isOpenDeleteDiscountTermAlert,
    onOpen: onOpenDeleteDiscountTermAlert,
    onClose: onCloseDeleteDiscountTermAlert,
  } = useDisclosure();

  const handleDiscountTermsChange = (
    field: string,
    value: string | number | any,
    index: number
  ) => {
    const updatedDiscountTerms = formData.businessTerms?.discountTerms.map(
      (term, termIndex) => {
        if (termIndex === index) {
          return {
            ...term,
            [field]: value,
          };
        }
        return term;
      }
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      businessTerms: {
        ...prevFormData.businessTerms,
        discountTerms: updatedDiscountTerms,
      },
    }));
  };

  useEffect(() => {
    if (!!allBrands?.length) {
      setBrands(
        allBrands?.map((brand: BrandOption) => ({
          _id: brand?._id,
          name: brand?.name,
        }))
      );
    }
  }, [isSuccessGetBrandsInfo, allBrands]);

  useEffect(() => {
    setCategories(
      allPartCategories?.map((category: CategoryOption) => ({
        _id: category._id,
        category: category.category,
      }))
    );
  }, [isSuccessGetPartInfo, allPartCategories]);

  const handleDeleteDiscountTerm = async (
    index: number,
    discountTermsId: string = ""
  ) => {
    const updatedDiscountTerms = [...formData.businessTerms!.discountTerms];
    updatedDiscountTerms.splice(index, 1);

    setFormData((prevFormData) => ({
      ...prevFormData,
      businessTerms: {
        ...prevFormData.businessTerms,
        discountTerms: updatedDiscountTerms,
      },
    }));

    if (discountTermsId) {
      const token = localStorage.getItem("MC_SUPER_ADMIN");
      try {
        await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/v1/suppliers/${formData.supplierId}/discount-terms/${discountTermsId}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        toast({
          description: "Successfully deleted",
          status: globalConstant.Success,
        });
      } catch (error) {
        toast({
          description: globalConstant.ERROR_MESSAGE,
          status: globalConstant.Error,
        });
      }
    } else {
      toast({
        description: "Successfully deleted",
        status: globalConstant.Success,
      });
    }
  };

  return (
    <HStack
      mb={index !== formData.businessTerms.discountTerms.length - 1 ? 5 : 1}
      key={index}
    >
      <Text mr="1" alignSelf={"flex-start"}>
        {index + 1}.
      </Text>
      <Stack
        w={"100%"}
        bg={`${secondaryColors.secondary_100}`}
        borderWidth={1}
        p="4"
        borderRadius={"md"}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          py="1"
          alignItems={"flex-start"}
        >
          <Box width={{ base: "100%", lg: "48%" }} mt={{ base: 6, lg: 0 }}>
            <GaraazFormLabel isRequired={false} text="Brands" />
            <Select
              bg="white"
              placeholder="Select Brands"
              onChange={(e) => {
                handleDiscountTermsChange("brands", e.target.value, index);
              }}
              value={formData.businessTerms.discountTerms[index].brands}
            >
              {brands?.map((brand: BrandOption, index: number) => (
                <option key={index}>{brand.name}</option>
              ))}
            </Select>
          </Box>

          <Box width={{ base: "100%", lg: "48%" }} mt={{ base: 6, lg: 0 }}>
            <GaraazFormLabel isRequired={false} text="Part category" />
            <Select
              bg="white"
              placeholder={"Select part category"}
              onChange={(e) => {
                handleDiscountTermsChange(
                  "partCategory",
                  e.target.value,
                  index
                );
              }}
              value={formData.businessTerms.discountTerms[index].partCategory}
            >
              {categories?.map((category: CategoryOption, index: number) => {
                return <option key={index}>{category.category}</option>;
              })}
            </Select>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems={"flex-start"}
          py="1"
        >
          <GaraazInput
            width="48%"
            label={"PURCHASE AMOUNT (upto)"}
            inputName="purchaseAmount"
            inputType="number"
            placeholder=""
            isRequired={false}
            inputLeftAddOn={
              <InputLeftAddon
                bg="whiteAlpha.900"
                borderWidth={1}
                children="&#x20B9;"
              />
            }
            value={formData.businessTerms.discountTerms[index].purchaseAmount}
            onChange={(e) => {
              handleDiscountTermsChange(
                "purchaseAmount",
                e.target.value,
                index
              );
            }}
            errorMsg={
              Number(
                formData.businessTerms.discountTerms[index].purchaseAmount
              ) < 0
                ? "Please add valid amount"
                : ""
            }
          />
          <GaraazInput
            width="48%"
            label={"FIXED DISCOUNT"}
            placeholder=""
            inputName="fixedDiscount"
            inputType="number"
            isRequired={false}
            inputRightAddOn={
              <InputRightAddon
                borderWidth={1}
                bg="whiteAlpha.900"
                children="%"
              />
            }
            onChange={(e) => {
              handleDiscountTermsChange("fixedDiscount", e.target.value, index);
            }}
            value={formData.businessTerms.discountTerms[index].fixedDiscount}
            errorMsg={
              Number(
                formData.businessTerms.discountTerms[index].fixedDiscount
              ) > 100 ||
              Number(
                formData.businessTerms.discountTerms[index].fixedDiscount
              ) < 0
                ? "Please add valid %"
                : ""
            }
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems={"flex-start"}
          py="1"
        >
          <GaraazInput
            width="48%"
            label={"TOD AMOUNT"}
            inputName="todAmount"
            placeholder=""
            inputType="number"
            isRequired={false}
            inputLeftAddOn={
              <InputLeftAddon
                borderWidth={1}
                bg="whiteAlpha.900"
                children="&#x20B9;"
              />
            }
            onChange={(e) => {
              handleDiscountTermsChange("todAmount", e.target.value, index);
            }}
            value={formData.businessTerms.discountTerms[index].todAmount}
            errorMsg={
              Number(formData.businessTerms.discountTerms[index].todAmount) < 0
                ? "Please add valid amount"
                : ""
            }
          />
          <GaraazInput
            width="48%"
            label={"PERIOD (in months)"}
            inputName="period"
            placeholder=""
            inputType="number"
            isRequired={false}
            onChange={(e) => {
              handleDiscountTermsChange("period", e.target.value, index);
            }}
            value={formData.businessTerms.discountTerms[index].period}
            errorMsg={
              Number(formData.businessTerms.discountTerms[index].period) < 0
                ? "Please add valid number"
                : ""
            }
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems={"flex-start"}
          py="1"
        >
          <GaraazInput
            width="48%"
            label={"TOD DISCOUNT"}
            placeholder=""
            inputName="todDiscount"
            inputType="number"
            isRequired={false}
            inputRightAddOn={
              <InputRightAddon
                borderWidth={1}
                bg="whiteAlpha.900"
                children="%"
              />
            }
            onChange={(e) => {
              handleDiscountTermsChange("todDiscount", e.target.value, index);
            }}
            value={formData.businessTerms.discountTerms[index].todDiscount}
            errorMsg={
              Number(formData.businessTerms.discountTerms[index].todDiscount) >
                100 ||
              Number(formData.businessTerms.discountTerms[index].todDiscount) <
                0
                ? "Please add valid %"
                : ""
            }
          />
          <></>
        </Box>
        <Button
          alignSelf={"flex-end"}
          mt={1}
          ml={"auto"}
          py="0"
          size={"sm"}
          bg="none"
          color={"red.400"}
          aria-label="Add discount term"
          _hover={{ backgroundColor: "#e9ecef !important" }}
          _focus={{ bg: "none", boxShadow: "none" }}
          onClick={onOpenDeleteDiscountTermAlert}
        >
          Delete
        </Button>
        <GaraazAlertDialog
          isOpen={isOpenDeleteDiscountTermAlert}
          onClose={onCloseDeleteDiscountTermAlert}
          submitBtnText="Delete"
          headerText={"Are you sure ?"}
          bodyText={`You are deleting Discount Term`}
          branchName={""}
          onSubmitButtonClick={() => {
            handleDeleteDiscountTerm(
              index,
              formData.businessTerms.discountTerms[index]?._id
            );
            onCloseDeleteDiscountTermAlert();
          }}
        />
      </Stack>
    </HStack>
  );
};

export default DiscountTerms;
