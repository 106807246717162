import {
    Box,
    Center,
    Text,
    LinkBox,
    LinkOverlay,

  } from '@chakra-ui/react';
  import { IoMdAdd } from 'react-icons/io';



const AddButton = (props: {onClick: () => void, name: string}) => {
    return (
      <LinkBox
        borderRadius='md'
        mr={8}
        mt={5}
        border='1px solid'
        borderColor='gray.200'
        flexBasis={{ base: '100%', lg: '30%' }}
        color='blue.500'
      >
        <Center height='40vh'>
          <LinkOverlay
            href=''
            onClick={(e) => {
              props.onClick()
              e.preventDefault();
            }}
          >
            <Box fontSize='8vw'>
              <IoMdAdd />
              <Text variant='boldNormal'>{props.name}</Text>
            </Box>
          </LinkOverlay>
        </Center>
      </LinkBox>
    );
  };

  export default AddButton;