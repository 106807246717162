/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo } from 'react';

import { Box } from '@chakra-ui/react';

import { useVerifyUser } from '../queryHooks';

import ModalComponent from '../../../components/ModalComponent';
import SubmitAndCloseButton from '../../../components/SubmitAndCloseButton';
import Enums from '../enums';


type Props = {
  isOpen: boolean;
  onClose: () => void;
  data: ISingleComponentUser
};

const ConfirmModal = ({isOpen, onClose, data }: Props) => {
const {mutate: verifyUser, isSuccess, isLoading } = useVerifyUser()

useEffect(() => {
  if(isSuccess){
    onClose();
  }
}, [isSuccess])

  const handleSubmit = useCallback(() => {
    verifyUser({data: {component: data.component, role: data.role}, userId: data._id})
  }, []);

  const bottomButton = useMemo(() => {
    return (
      <SubmitAndCloseButton
        isDisabled={isLoading}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    );
  }, [handleSubmit, onClose, isLoading]);

  return (
    <ModalComponent
      button={bottomButton}
      title={Enums.CONFIRM_VERIFICATION}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box>
        This will give {data.firstName} the access to <strong>{data.component}</strong> having the role as <strong>{data.role}</strong>. <br/> Are you
        sure you want to verify?
      </Box>
    </ModalComponent>
  );
};

export default ConfirmModal;
