
import { Box } from '@chakra-ui/react';

export const Loading = () => {
    return (
        <Box mt={5}>
            ...Loading
        </Box>
    );
};

export default Loading;