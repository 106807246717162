import { Flex, Alert, AlertIcon, useDisclosure } from '@chakra-ui/react';
import _ from 'lodash';

import { pageTitles } from '../../utils/paths';
import { useGetMobileAds } from './queryHooks';

import AdsCard from './Components/AdsCard';
import CreateAdvert from './Components/CreateAdvert';
import PageScaffold from '../../components/PageScaffold';
import Loading from '../../components/Loading';
import AddButton from '../../components/AddButton';
import Enums from './Enums';

const Index = () => {
  const {data: mobileAds, isLoading: gettingMobileAds} = useGetMobileAds();
  const { onClose, onOpen, isOpen } = useDisclosure();

  return (
    <PageScaffold title={pageTitles.adsManagement}>
      <Alert status='info'>
        <AlertIcon />
        Configure Mobile Adverts in this section . Changes here would be
        immediately applied to the running apps.
      </Alert>
      {gettingMobileAds ? (
        <Loading />
      ) : (
        <Flex flexWrap='wrap'>
          {_.isEmpty(mobileAds) ? (
            <AddButton name={Enums.ADD_ADVERTS} onClick={onOpen} />
          ) : (
            <AdsCard {...mobileAds} />
          )}
        </Flex>
      )}
      <CreateAdvert isOpen={isOpen} onClose={onClose} />
    </PageScaffold>
  );
};

export default Index;
