import { Route, Routes } from "react-router-dom";
import AddNewSparePart from "./components/AddNewSparePart";
import SparePartList from "./components/SparePartList";
import EditCompatibility from "./components/EditCompatibility";

const Index = () => {
  return (
    <Routes>
      <Route path="/" element={<SparePartList></SparePartList>}></Route>
      <Route
        path="/overview"
        element={<EditCompatibility></EditCompatibility>}
      ></Route>
    </Routes>
  );
};

export default Index;
