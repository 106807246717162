import { Image } from '@chakra-ui/react';


 const ImageComponent = (props: {path: string}) => {
  return (
    <Image
      height='100%'
      width='100%'
      objectFit='contain'
      objectPosition='0% 0%'
      src={props.path}
      alt='advert Video'
      borderRadius='md'
    />
  );
};

export default ImageComponent;
