import { Box, Switch, Text } from "@chakra-ui/react";
import { useUserDetailsContext } from "../../../Contexts/userDetailsContext";
import getUserRole from "../../../utils/getUserRole";
import { useState } from "react";
import services from "../services";
import useNotification from "../../../utils/useNotification";

type ActionCellProps = {
  customerData: any;
};

const ActionCell: React.FC<ActionCellProps> = ({ customerData }) => {
  const userDetails = useUserDetailsContext();
  const toast = useNotification();

  const [isCustomerBlocked, setIsCustomerBlocked] = useState(
    customerData.isBlocked
  );

  async function handleCustomerBlockToggleChange(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    try {
      const updatedCustomer = await services.editCustomerDetails({
        customerId: customerData._id,
        customerData: { isBlocked: e.target.checked },
      });
      setIsCustomerBlocked(updatedCustomer.isBlocked);
      toast({
        description: `Customer ${
          updatedCustomer.isBlocked === true ? "Blocked" : "Unblocked"
        } successfully`,
        status: "success",
      });
    } catch (error) {
      toast({
        description: "Something went wrong, Please try again",
        status: "error",
      });
    }
  }
  return (
    <Box>
      <Switch
        isChecked={isCustomerBlocked}
        isReadOnly={!(getUserRole(userDetails) === "SUPER_ADMIN")}
        onChange={handleCustomerBlockToggleChange}
        size="lg"
      />
    </Box>
  );
};

export default ActionCell;
