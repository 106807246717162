import endpoints from '../../utils/endpoints';
import { authAxios as axios } from '../../config/axios';
import globalConstant from '../../utils/constants';





class Services {
    async getMobileAds() {
        const response = await axios({
            method: globalConstant.GET,
            url: endpoints.mobileAds
        })
        return  response.data
    }



    async createMobileAds(payload: {data: FormData}) {
        const response = await axios({
            method: globalConstant.POST,
            url: endpoints.mobileAds,
            data: payload.data,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        return response.data
    }

    async deleteMobileAds(payload: { advertId: string }) {
        const response = await axios({
            method: globalConstant.DELETE,
            url: `${endpoints.mobileAds}/${payload.advertId}`,
        })
        return response.data
    }


}

export default new Services()