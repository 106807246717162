import endpoints from '../../utils/endpoints';
import { authAxios as axios } from '../../config/axios';
import globalConstant from '../../utils/constants';


class Services {
    async getUnverifiedUsers() {
        const response = await axios({
            method: globalConstant.GET,
            url: endpoints.getUnVerifiedUsers
        })

        console.log('HERE',response.data);
        return response.data
    }


    async verifyUser(payload: {data : {component: string, role: string}, userId: string}) {
        const response = await axios({
            method: globalConstant.PATCH,
            url: `${endpoints.verifyUser(payload.userId)}`,
            data: payload.data
        })
        return response.data
    }


    async unVerifyUser(payload: {data : {component: string, role: string}, userId: string}) {
        const response = await axios({
            method: globalConstant.PATCH,
            url: `${endpoints.unVerifyUser(payload.userId)}`,
            data: payload.data
        })
        return response.data
    }

   

   

}

export default new Services()