import React from 'react';
import { useNavigate } from 'react-router-dom';

const withRouter = (Component: typeof React.Component) => {
  const Wrapper = (props: React.PropsWithChildren) => {
    const navigate = useNavigate();
    
    return (
      <Component
        navigate={navigate}
        {...props}
        />
    );
  };
  
  return Wrapper;
};

export default withRouter;