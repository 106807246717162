import { ChangeEvent, Key, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Switch,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
  useGetAllSuppliers,
  useGetCustomerDetails,
  useUpdateCustomerDetails,
} from "../queryHooks";
import GaraazSpinner from "../../../components/GaraazSpinner";
import GaraazFormLabel from "../../../components/GarrazFormLabel";
import AddressDetailsFormCustomer from "./AddressDetailsFormCustomer";
import SupplierAssociationInfo from "./SupplierAssociationInfo";
import GaraazBasicInfoStack from "../../../components/GaraazBasicInfoStack";
import { validateEmail } from "../../../utils/validationHelpers";
import { useGetMobileConfig } from "../queryHooks";
import PageScaffold from "../../../components/PageScaffold";
import { AddressDetailsType, Customer, SupplierAssociation } from "../types";
import useNotification from "../../../utils/useNotification";
import { messages } from "../constants";
import services from "../services";
import { successColors } from "../../../utils/designSystem";
import { BiSave } from "react-icons/bi";
import { useUserDetailsContext } from "../../../Contexts/userDetailsContext";
import getUserRole from "../../../utils/getUserRole";

const BasicProfileDetails = () => {
  const userDetails = useUserDetailsContext();
  const { mobileNumber } = useParams();
  const toast = useNotification();
  const { data: result, isFetched } = useGetCustomerDetails(mobileNumber);
  const [customerData, setCustomerData] = useState<Customer | undefined>(
    result
  );
  const { data: allSuppliers } = useGetAllSuppliers();
  const [customerName, setCustomerName] = useState<string | undefined>(
    result?.name
  );
  const [customerAddressData, setCustomerAddressData] =
    useState<AddressDetailsType>({
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      countryCode: "+91",
      pinCode: "",
    });

  const [touchedFields, setTouchedFields] = useState<{
    [key: string]: boolean;
  }>({});

  const { mutateAsync } = useUpdateCustomerDetails();
  const { data: customerTypes } = useGetMobileConfig();

  const handleFieldBlur = (field: any) => {
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [field]: true,
    }));
  };

  const isFieldTouched = (field: any) => {
    return touchedFields[field];
  };

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setCustomerData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleChangeAddressInputs(name: string, value: string) {
    setCustomerAddressData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleUpdateSupplierAssociation = (
    index: number,
    field: string,
    value: string
  ) => {
    setCustomerData((prevCustomerData) => {
      if (!prevCustomerData) {
        return prevCustomerData;
      }

      const updatedAssociations = [...prevCustomerData.supplierAssociations];
      updatedAssociations[index] = {
        ...updatedAssociations[index],
        [field]: value,
      };

      return {
        ...prevCustomerData,
        supplierAssociations: updatedAssociations,
      };
    });
  };

  useEffect(() => {
    setCustomerData(result);
    setCustomerName(result?.name);
    if (result?.addresses?.length) {
      setCustomerAddressData(result.addresses[0]);
    }
  }, [result, isFetched]);

  async function handleSaveButtonClick() {
    if (
      !customerData?.name ||
      !customerData?.type ||
      (!customerData?.creditLimit && customerData?.creditLimit !== 0) ||
      customerData.creditLimit < 0
    ) {
      toast({
        description: messages.FILL_REQUIRED_FEIEDS_IN_BASIC_INFO,
        status: messages.Error,
      });
      return;
    }

    if (
      !customerAddressData.addressLine1 ||
      !customerAddressData.city ||
      !customerAddressData.state ||
      !customerAddressData.pinCode
    ) {
      toast({
        description: messages.FILL_REQUIRED_FEIEDS_IN_ADDRESSES,
        status: messages.Error,
      });
      return;
    }

    if (
      !!customerData?.supplierAssociations?.length &&
      !customerData?.supplierAssociations.every((sa) => sa.supplierId)
    ) {
      toast({
        description: messages.FILL_REQUIRED_FEIEDS_IN_SUPPLIER_ASSOCIATIONS,
        status: messages.Error,
      });
      return;
    }

    const finalObj = {
      addresses: customerAddressData
        ? [
            {
              isDefault: true,
              ...customerAddressData,
            },
          ]
        : undefined,

      name: customerData?.name ? customerData?.name : undefined,
      email: customerData?.email ? customerData?.email : undefined,
      gstin: customerData?.gstin ? customerData?.gstin : undefined,
      pan: customerData?.pan ? customerData?.pan : undefined,
      type: customerData?.type ? customerData?.type : undefined,
      creditLimit:
        getUserRole(userDetails) === "SUPER_ADMIN"
          ? customerData.creditLimit
          : undefined,
      isBlocked:
        getUserRole(userDetails) === "SUPER_ADMIN"
          ? customerData.isBlocked
          : undefined,

      supplierAssociations: customerData?.supplierAssociations
        ? customerData.supplierAssociations.map((sa) => {
            const { supplierId, supplierBranchId, supplierCode } = sa;
            return {
              supplierId,
              ...(supplierBranchId && { supplierBranchId }),
              ...(supplierCode && { supplierCode }),
            };
          })
        : undefined,
    };
    await mutateAsync({ customerId: result._id, customerData: finalObj }).then(
      () => {
        setCustomerName(finalObj?.name);
      }
    );
  }

  const handleDeleteSupplierAssociation = async (
    index: number,
    supplierId: string = ""
  ) => {
    setCustomerData((prevCustomerData) => {
      if (!prevCustomerData) {
        return prevCustomerData;
      }

      const updatedAssociations = [...prevCustomerData.supplierAssociations];
      updatedAssociations.splice(index, 1);

      return {
        ...prevCustomerData,
        supplierAssociations: updatedAssociations,
      };
    });

    if (result._id && supplierId) {
      try {
        await services.deleteSupplierAssociation(result._id, supplierId);
      } catch (error) {
        toast({
          description: messages.somethingWentWrong,
          status: messages.Error,
        });
      }
    }
  };

  const availableSuppliers = allSuppliers?.filter((supplier: any) => {
    return !customerData?.supplierAssociations.some(
      (association) => association.supplierId === supplier._id
    );
  });
  const handleAddSupplierAssociation = () => {
    if (!availableSuppliers.length) {
      toast({
        description: messages.NO_SUPPLIERS_AVAILBALE,
        status: messages.Error,
      });
      return;
    }

    if (
      customerData?.supplierAssociations.some(
        (association) => !association.supplierId
      )
    ) {
      toast({
        description: messages.FILL_REQUIRED_FEIEDS_IN_SUPPLIER_ASSOCIATIONS,
        status: messages.Error,
      });
      return;
    }

    const newSupplierAssociation = {
      supplierId: "",
      supplierBranchId: "",
      supplierCode: "",
    };

    setCustomerData((prevCustomerData) => {
      if (!prevCustomerData) {
        return prevCustomerData;
      }

      const updatedAssociations = [
        ...prevCustomerData.supplierAssociations,
        newSupplierAssociation,
      ];

      return {
        ...prevCustomerData,
        supplierAssociations: updatedAssociations,
      };
    });
  };

  return (
    <PageScaffold
      title={customerName || ""}
      hasButton={true}
      buttonText={
        <IconButton
          position={"fixed"}
          right={4}
          aria-label="saveBtn"
          size="md"
          color={"white"}
          icon={<BiSave size={"1.5rem"} />}
          onClick={handleSaveButtonClick}
        />
      }
    >
      <GaraazBasicInfoStack />
      <Box p={4} width={"100%"}>
        <Flex alignItems="center" justify="space-around" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="name" flex={1}>
            <GaraazFormLabel text="name" isRequired={true} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                type="text"
                placeholder="Enter your name"
                width={"100%"}
                name="name"
                value={customerData?.name}
                onChange={handleInputChange}
                onBlur={() => handleFieldBlur("name")}
              />
              {isFieldTouched("name") && !customerData?.name?.length && (
                <Text color="red" fontSize={"sm"}>
                  Enter a valid name
                </Text>
              )}
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="contactNumber" flex={1}>
            <GaraazFormLabel text="Mobile Number" isRequired={true} />
          </FormControl>

          <FormControl ml={4} width={"75%"}>
            <InputGroup>
              <InputLeftAddon bg="gray.100" children="+91" />
              <Input
                type="number"
                placeholder="Enter mobile number"
                width={"100%"}
                name="mobileNumber"
                value={
                  customerData?.mobileNumber
                    ? customerData.mobileNumber?.substring(2)
                    : ""
                }
                isReadOnly={true}
              />
            </InputGroup>
          </FormControl>
        </Flex>

        <Flex alignItems="center" justify="space-around" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="name" flex={1}>
            <GaraazFormLabel text="code" isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                type="text"
                placeholder="Enter your code"
                width={"100%"}
                isReadOnly={true}
                name="code"
                value={customerData?.code}
              />
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="gstin" flex={1}>
            <GaraazFormLabel text="type" isRequired={true} />
          </FormControl>
          <Flex width={"75%"}>
            <Select
              name="type"
              placeholder={"Select customer type"}
              value={customerData?.type}
              onChange={(e) => {
                setCustomerData((prevData: any) => ({
                  ...prevData,
                  type: e.target.value,
                }));
              }}
            >
              {customerTypes?.customer_types?.map(
                (type: string, index: any) => (
                  <option
                    key={`${type}-${index}`}
                    value={type}
                    selected={customerData?.type === type}
                  >
                    {type}
                  </option>
                )
              )}
            </Select>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="email" flex={1}>
            <GaraazFormLabel text="email" isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                type="email"
                placeholder="Enter email"
                width={"100%"}
                name="email"
                value={customerData?.email}
                onChange={handleInputChange}
                onBlur={() => handleFieldBlur("email")}
              />
              {isFieldTouched("email") &&
                !!customerData?.email?.length &&
                !validateEmail(customerData?.email) && (
                  <Text color="red" fontSize={"sm"}>
                    Enter a valid email address
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="pan" flex={1}>
            <GaraazFormLabel text="pan" isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                type="text"
                placeholder="Enter PAN number"
                width={"100%"}
                name="pan"
                value={customerData?.pan}
                onChange={handleInputChange}
                onBlur={() => handleFieldBlur("pan")}
              />

              {isFieldTouched("pan") &&
                !!customerData?.pan?.length &&
                customerData?.pan?.length !== 10 &&
                customerData?.pan?.length > 0 && (
                  <Text color="red" fontSize={"sm"}>
                    Enter a valid PAN
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="gstin" flex={1}>
            <GaraazFormLabel text="Gst" isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                type="text"
                placeholder="Enter GST number"
                width={"100%"}
                name="gstin"
                value={customerData?.gstin}
                onChange={handleInputChange}
                onBlur={() => handleFieldBlur("gstin")}
              />
              {isFieldTouched("gstin") &&
                !!customerData?.gstin?.length &&
                customerData?.gstin?.length !== 15 && (
                  <Text color="red" fontSize={"sm"}>
                    Enter a valid GST
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" width={"70%"} mb={4}>
          <FormControl width={"25%"} id="isXpress" flex={1}>
            <GaraazFormLabel text="isXpress" isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Switch
                isChecked={customerData?.isXpress}
                isReadOnly={true}
                size="lg"
              />
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="creditLimit" flex={1}>
            <GaraazFormLabel text="Credit Limit" isRequired={true} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                isReadOnly={!(getUserRole(userDetails) === "SUPER_ADMIN")}
                type="number"
                placeholder="Enter Credit Limit"
                width={"100%"}
                name="creditLimit"
                value={customerData?.creditLimit}
                onChange={handleInputChange}
                onBlur={() => handleFieldBlur("creditLimit")}
              />
              {isFieldTouched("creditLimit") &&
                customerData?.creditLimit !== 0 &&
                (!customerData?.creditLimit ||
                  customerData?.creditLimit < 0) && (
                  <Text color="red" fontSize={"sm"}>
                    Enter a valid Credit Limit
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>
        <Flex alignItems="center" justify="space-between" width={"70%"}>
          <FormControl width={"25%"} id="isBlocked" flex={1}>
            <GaraazFormLabel text="Blocked" isRequired={true} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Switch
                isChecked={customerData?.isBlocked}
                isReadOnly={!(getUserRole(userDetails) === "SUPER_ADMIN")}
                onChange={(e) => {
                  setCustomerData((prevData: any) => {
                    return {
                      ...prevData,
                      isBlocked: e.target.checked,
                    };
                  });
                }}
                size="lg"
              />
            </FormControl>
          </Flex>
        </Flex>
      </Box>

      <Tabs isLazy={true} position="relative" variant="styled">
        <TabList borderBottom={"1px solid lightgrey"}>
          <Tab
            _selected={{ border: "none", outline: "none", boxShadow: "none" }}
          >
            Address
          </Tab>
          <Tab
            _selected={{ border: "none", outline: "none", boxShadow: "none" }}
          >
            Associations
          </Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="blue.500"
          borderRadius="1px"
        />
        <TabPanels>
          <TabPanel>
            {!customerData?.addresses?.length && (
              <Text textAlign={"center"} fontWeight={"bold"}>
                Address is not available
              </Text>
            )}
            {!!customerData?.addresses?.length && (
              <AddressDetailsFormCustomer
                addressLine1={customerAddressData.addressLine1}
                addressLine2={customerAddressData.addressLine2}
                city={customerAddressData.city}
                state={customerAddressData.state}
                pinCode={customerAddressData.pinCode}
                countryCode={customerAddressData.countryCode}
                handleChangeAddressInputs={handleChangeAddressInputs}
              />
            )}
          </TabPanel>
          <TabPanel px="0">
            {!!customerData?.supplierAssociations?.length &&
              customerData?.supplierAssociations?.map(
                (
                  supplier: SupplierAssociation,
                  index: Key | number | undefined
                ) => {
                  return (
                    <SupplierAssociationInfo
                      key={index}
                      indexNo={index}
                      supplierId={supplier.supplierId}
                      supplierBranchId={supplier.supplierBranchId}
                      supplierCode={supplier.supplierCode}
                      onUpdateSupplierAssociation={
                        handleUpdateSupplierAssociation
                      }
                      onDeleteSupplierAssociation={
                        handleDeleteSupplierAssociation
                      }
                      availableSuppliers={availableSuppliers}
                    />
                  );
                }
              )}
            <HStack
              mt={!!customerData?.supplierAssociations?.length ? "4" : "0"}
              justifyContent={"space-between"}
            >
              {!!customerData?.supplierAssociations?.length ? (
                <Box />
              ) : (
                <Text fontSize={"sm"} fontWeight={"bold"}>
                  No Supplier Associations
                </Text>
              )}
              <Button
                _hover={{ backgroundColor: "#e9ecef !important" }}
                _focus={{ bg: "none", boxShadow: "none" }}
                size="sm"
                bg="none"
                color={"green.400"}
                onClick={handleAddSupplierAssociation}
              >
                + Add
              </Button>
            </HStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageScaffold>
  );
};

export default BasicProfileDetails;
