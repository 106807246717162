import {
  useDisclosure,
  Box,
  Flex,
  FormControl,
  Input,
  Text,
} from "@chakra-ui/react";

import { MdEdit } from "react-icons/md";
import GaraazIconButton from "../../../components/GaraazIcon";
import PopupModal from "../../../components/PopupModal";
import GaraazFormLabel from "../../../components/GarrazFormLabel";
import { useState } from "react";

type ActionCellProps = {
  row: any;
  handleConformEditUPN: (oldUPN: string, newUPN: string) => void;
};

const ActionCell: React.FC<ActionCellProps> = ({
  row,
  handleConformEditUPN,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [newUPN, setNewUPN] = useState("");
  return (
    <Box>
      <GaraazIconButton
        onClick={() => {
          onOpen();
          setNewUPN("");
        }}
        icon={<MdEdit></MdEdit>}
      ></GaraazIconButton>

      <PopupModal
        isOpen={isOpen}
        onClose={onClose}
        bodyText={
          <Box>
            <Flex
              alignItems="center"
              justify="space-between"
              mb={4}
              width={"70%"}
            >
              <FormControl width={"25%"} id="oldUPN" flex={1}>
                <GaraazFormLabel text="Old UPN" isRequired={false} />
              </FormControl>
              <Flex width={"75%"}>
                <FormControl flex={1}>
                  <Text>{row.oldUPN}</Text>
                </FormControl>
              </Flex>
            </Flex>
            <Flex
              alignItems="center"
              justify="space-between"
              mb={4}
              width={"70%"}
            >
              <FormControl width={"25%"} id="newUPN" flex={1}>
                <GaraazFormLabel text="New UPN" isRequired={true} />
              </FormControl>
              <Flex width={"75%"}>
                <FormControl flex={1}>
                  <Input
                    type="text"
                    placeholder="Enter New UPN"
                    name="newUPN"
                    value={newUPN}
                    onChange={(e) => {
                      setNewUPN(e.target.value);
                    }}
                  />
                </FormControl>
              </Flex>
            </Flex>
          </Box>
        }
        title={"Edit UPN"}
        hasFooter={true}
        secondaryActionButtonText="Confirm"
        onOk={() => {
          handleConformEditUPN(row.oldUPN, newUPN);
          onClose();
        }}
        onCancel={() => {
          onClose();
        }}
        isOkButtonDisabled={newUPN.trim() === ""}
      ></PopupModal>
    </Box>
  );
};

export default ActionCell;
