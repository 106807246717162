import endpoints from "../../utils/endpoints";
import { authAxios as axios } from "../../config/axios";
import globalConstant from "../../utils/constants";

class Services {
  async getCars() {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.fetchCarData,
    });
    return response.data;
  }

  async setBoodmoModel(carId: any, newBoodmoModel: any) {
    const response = await axios({
      method: globalConstant.POST,
      url: endpoints.setBoodmoModel(carId),

      data: {
        boodmoModel: newBoodmoModel,
      },
    });
    return response.data;
  }
}

export default new Services();
