import React, { useState } from "react";
import {
  Text,
  Box,
  Button,
  Input,
  ListItem,
  OrderedList,
  Spinner,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";

import { supplierFormData } from "./AddSupplier";
import useNotification from "../../../utils/useNotification";
import services from "../services";
import globalConstant from "../../../utils/constants";
import { GrUpload } from "react-icons/gr";
import { successColors } from "../../../utils/designSystem";

type SupplierDocumentsProps = {
  formData: supplierFormData;
  setFormData: React.Dispatch<React.SetStateAction<supplierFormData>>;
};

const SupplierDocuments: React.FC<SupplierDocumentsProps> = ({
  formData,
  setFormData,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
  const [toSendSelectedFileName, setToSendSelectedFileName] = useState<
    string[]
  >([]);

  const [isDocumentsUploading, setIsDocumentsUploading] = useState(false);

  const toast = useNotification();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAddMediaClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.addEventListener("change", handleDocumentInputChange);
    input.click();
  };
  const handleDocumentInputChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const selectedDocuments = target.files;
    if (selectedDocuments) {
      for (let i = 0; i < selectedDocuments?.length; i++) {
        setToSendSelectedFileName((previousData) => {
          return [...previousData, selectedDocuments[i].name];
        });
      }
      setSelectedFiles(Array.from(selectedDocuments));
    }
    onOpen();
  };
  async function handleUploadClick() {
    if (selectedFiles) {
      setIsDocumentsUploading(true);
      try {
        for (let i = 0; i < selectedFiles.length; i++) {
          const formData = new FormData();
          formData.append("media", selectedFiles[i]);
          const response = await services.addMedia(formData);
          setFormData((previousFormData) => {
            return {
              ...previousFormData,
              documents: [
                ...previousFormData.documents,
                {
                  mediaId: response[0]._id,
                  name: toSendSelectedFileName[i],
                  path: response[0].path,
                },
              ],
            };
          });
        }
      } catch (error) {
        toast({
          description: "Error Occured in Uploading Documents",
          status: globalConstant.Error,
        });
      } finally {
        setIsDocumentsUploading(false);
        setSelectedFiles(null);
        setToSendSelectedFileName([]);
        onClose();
      }
    }
  }

  return (
    <>
      <Box mt={5} width={"100%"}>
        <IconButton
          isDisabled={isDocumentsUploading}
          position={"absolute"}
          right={0}
          size={"sm"}
          bg={"white"}
          aria-label="Upload"
          icon={<GrUpload></GrUpload>}
          border={"1px solid grey"}
          onClick={handleAddMediaClick}
          _focus={{ backgroundColor: "white" }}
          _hover={{ backgroundColor: "#008080 !important" }}
        ></IconButton>{" "}
        {formData.documents.length === 0 && (
          <Text fontSize={"sm"} mt={5} color={"#64748B"}>
            No Documents Uploaded Yet{" "}
          </Text>
        )}
        <OrderedList mt={5} mb={5}>
          {formData.documents.map((mediaDocument, index) => (
            <ListItem width={"50%"} mt={2} key={index}>
              {" "}
              <a
                onClick={async (e) => {
                  if (!mediaDocument.path) {
                    const mediaObject = await services.getMedia(
                      mediaDocument.mediaId
                    );
                    setFormData((previousFormData) => {
                      let newDocuments = previousFormData.documents;
                      newDocuments[index].path = mediaObject?.path;
                      return {
                        ...previousFormData,
                        documents: newDocuments,
                      };
                    });
                    const a = document.createElement("a");
                    a.href = mediaObject?.path;
                    a.download = mediaDocument.name;
                    a.click();
                  } else {
                    const a = document.createElement("a");
                    a.href = mediaDocument?.path;
                    a.download = mediaDocument.name;
                    a.click();
                  }
                }}
                style={{ color: "#008080", cursor: "pointer" }}
              >
                {mediaDocument.name}{" "}
              </a>{" "}
            </ListItem>
          ))}
        </OrderedList>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Rename Documents</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              setSelectedFiles(null);
              setToSendSelectedFileName([]);
            }}
          />
          <ModalBody>
            <Box mt={5}>
              {toSendSelectedFileName.map((selectedFileName, index) => (
                <Box display={"flex"} key={index} mb={1}>
                  <Input
                    disabled={isDocumentsUploading}
                    type="text"
                    value={toSendSelectedFileName[index]}
                    onChange={(e) => {
                      let newList = toSendSelectedFileName.map((item, index1) =>
                        index1 === index ? e.target.value : item
                      );

                      setToSendSelectedFileName(newList);
                    }}
                  />
                </Box>
              ))}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              bgColor={successColors.success_100}
              onClick={handleUploadClick}
              isDisabled={toSendSelectedFileName.some(
                (item) => item.trim() === ""
              )}
            >
              {isDocumentsUploading && <Spinner></Spinner>}
              {!isDocumentsUploading && "Done"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SupplierDocuments;
