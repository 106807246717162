/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useEffect } from 'react';

import { Box } from '@chakra-ui/react';

import { useUnverifyUser } from '../queryHooks';

import ModalComponent from '../../../components/ModalComponent';
import SubmitAndCloseButton from '../../../components/SubmitAndCloseButton';
import Enums from '../enums';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  data: ISingleComponentUser
};

const DeleteModal = ({isOpen, onClose, data}: Props) => {
  const {mutate: unVerifyUser, isSuccess, isLoading } = useUnverifyUser();

  useEffect(() => {
    if(isSuccess){
      onClose();
    }
  }, [isSuccess])

  const handleSubmit = useCallback(() => {
    unVerifyUser({data: {component: data.component, role: data.role}, userId: data._id})
  }, []);

  const bottomButton = useMemo(() => {
    return (
      <SubmitAndCloseButton
        isDisabled={isLoading}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        onClose={onClose}
        okText='Decline'
        variant='delete'
      />
    );
  }, [handleSubmit, onClose, isLoading]);

  return (
    <ModalComponent
      button={bottomButton}
      title={Enums.DELCINE_VERIFICATION}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box>
        This will decline {data.firstName}'s intent of having access to <strong>{data.component}</strong>  as an <strong>{data.role}</strong>. <br/> Are you
        sure you want to delete?
      </Box>
    </ModalComponent>
  );
};

export default DeleteModal;
