import { Box, Select } from '@chakra-ui/react';

interface Props {
    onSelect: (month: string) => void;
}

const SelectMonth = (props: Props) => {
    const currentMonth = new Date().toLocaleString('default', { month: 'long' });
    return (
        <Box width="150px" height="40px">
            <Select textColor={"#FFFFFF"} defaultValue={currentMonth} onChange={e => props.onSelect(e.target.value)} minWidth="150px" minHeight="40px">
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
            </Select>
        </Box>
    );
};

export default SelectMonth;