import endpoints from "../../utils/endpoints";
import { authAxios as axios } from "../../config/axios";
import globalConstant from "../../utils/constants";

class Services {
  async getUniqueUPNs() {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getUniqueUPNs,
    });

    return response.data;
  }
  async createNewSparePart(payload: any) {
    const response = await axios({
      method: globalConstant.POST,
      url: "https://data.mongodb-api.com/app/triggers-dmmft/endpoint/createNewSparePart",
      data: payload,
    });

    return response.data;
  }

  async getSparePartByQuery(query: string) {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getSpareParts + query,
    });
    return response.data;
  }

  async fetchCars() {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getCars,
    });

    return response.data;
  }

  async postSparePartCompatibilityRequest(payload: any) {
    const response = await axios({
      method: globalConstant.POST,
      url: endpoints.postPartIdentificationRequest,
      data: payload,
    });
    return response.data;
  }

  async getSparePartById(sparePartId: any) {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getSparePartById(sparePartId),
    });
    return response.data;
  }
}

export default new Services();
