import {
    Box,
    Flex,
    HStack,
  } from '@chakra-ui/react';

  
  interface Props{
    media: React.ReactNode
    rightMenu?: React.ReactNode
    leftMenu?: React.ReactNode
    width?: string
  }


  const CardScaffold = (props: Props) => {
    return (
      <Box
        borderRadius='md'
        width={props.width || { base: '100%', lg: '30%' }}
        height='40vh'
        boxShadow='md'
        mr={8}
        mt={5}
      >
        <Box
          borderBottom='1px solid'
          borderColor='gray.200'
          height='80%'
          overflow='hidden'
        >
        {props.media}
        </Box>
        <Box height='20%' p={3}>
          <Flex justifyContent='space-between'>
            <HStack>
             {props.leftMenu}
            </HStack>
            <HStack>
              {props.rightMenu}
            </HStack>
          </Flex>
        </Box>
      </Box>
    );
  };
  
  export default CardScaffold;
  