import { useMemo, ReactNode, HTMLProps, useRef, useEffect } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
} from '@tanstack/react-table';
import {
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Center,
  Text,
} from '@chakra-ui/react';
import { TData } from '../utils/types';

interface Props {
  columns: ColumnDef<TData, object | ReactNode>[];
  data: TData[];
  setRowSelection?: React.Dispatch<React.SetStateAction<{}>>;
  rowSelection?: { [key: string]: any };
  hideMaker?: boolean;
  noDataText?: string;
}

const Index = ({
  columns,
  data,
  setRowSelection,
  rowSelection,
  hideMaker,
  noDataText
}: Props) => {

  const marker = useMemo<ColumnDef<TData>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className='px-1'>
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
    ],
    [],
  );
  const table = useReactTable<TData>({
    data,
    columns: [...(hideMaker ? [] : marker), ...columns],
    state: {
      rowSelection,
    },

    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  });

  const tableHeadRows = table.getHeaderGroups().map((headerGroup) => (
    <Tr key={headerGroup.id} backgroundColor='primary' color='white'>
      {headerGroup.headers.map((header) => (
        <Th
          color='white'
          py={5}
          key={header.id}
          layerStyle='dark'
          textAlign='center'
          style={{ wordBreak: 'keep-all' }}
        >
          {header.isPlaceholder
            ? null
            : flexRender(header.column.columnDef.header, header.getContext())}
        </Th>
      ))}
    </Tr>
  ));

  const tableBodyRows = table.getRowModel().rows.map((row) => (
    <Tr key={row.id}>
      {row.getVisibleCells().map((cell, index, cells) => {
        let borderRight = index < cells.length - 1 ? '1px' : '0px';
        return (
          <Td
            key={cell.id}
            maxWidth={`${100 / cells.length}vw`}
            borderRightWidth={borderRight}
            textAlign='center'
            style={{ wordBreak: 'break-all' }}
            overflowY='auto'
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
    </Tr>
  ));

  return (
    <TableContainer>
      {data.length ? (
        <Table>
          <Thead>{tableHeadRows}</Thead>
          <Tbody>{tableBodyRows}</Tbody>
        </Table>
      ) : (
        <Center>
          <Text>{noDataText? 'No New User Intent to be Verified' : 'No Data'}</Text>
        </Center>
      )}
    </TableContainer>
  );
};

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return <input type='checkbox' ref={ref} {...rest} />;
}

export default Index;
