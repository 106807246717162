import endpoints from "../../utils/endpoints";
import { authAxios as axios } from "../../config/axios";
import globalConstant from "../../utils/constants";

class Services {
  async getUniqueCarGenerations() {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getUniqueGenerations,
    });

    return response.data;
  }

  async getCarById(carId: any) {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getCarById(carId),
    });
    return response.data;
  }
  async postSparePartCompatibilityRequest(payload: any) {
    const response = await axios({
      method: globalConstant.POST,
      url: endpoints.postPartIdentificationRequest,
      data: payload,
    });
    return response.data;
  }
  async getCarByQuery(query: string) {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getCarsByQuery + query,
    });
    return response.data;
  }
}

export default new Services();
