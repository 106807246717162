import { Box, Text } from "@chakra-ui/react";

interface Prop {
  title: string;
  value: string;
}

const StatsCard = (props: Prop) => {
  let newValue = props.value;

  if (props.title.endsWith("VALUE")) {
    newValue = "₹" + props.value;
  } else if (props.title.endsWith("RATIO")) {
    newValue = props.value + "%";
  }

  return (
    <Box
      borderRadius="md"
      width={{ base: "100%", lg: "30%" }}
      py={10}
      boxShadow="dark-lg"
      gap={5}
      alignSelf="center"
      mt={5}
      bg="#1E293B"
    >
      <Text
        color="white"
        px="4"
        // isTruncated
        alignSelf="center"
        textTransform="capitalize"
        textAlign="center"
        fontWeight="extrabold"
        fontSize="5xl"
      >
        {newValue || 0}
      </Text>
      <Text
        alignSelf="center"
        color="white"
        textTransform="uppercase"
        textAlign="center"
        fontSize="sm"
        mt={4}
      >
        {props.title}
      </Text>
    </Box>
  );
};

export default StatsCard;
