import Enums from './enums';
import * as yup from 'yup';

const schema = yup
  .object()
  .shape({
    key: yup
      .string()
      .matches(/^[A-Za-z_-]+$/, Enums.KEY_MATCH_MESSAGE)
      .trim()
      .required(),
    value: yup
      .string()
      .matches(/^[\s\S]*$/, Enums.VALUE_MATCH_MESSAGE)
      .trim()
      .required(),
  })
  .required();

export default schema;