import endpoints from '../../utils/endpoints';
import { authAxios as axios } from '../../config/axios';
import globalConstant from '../../utils/constants';

class Services {

    async getMonthlyCustomerMetrics(selectedMonth: string, selectedYear: string) {
        const response = await axios({
            method: globalConstant.GET,
            url: `${endpoints.customerMonthlyMetrics}?month=${selectedMonth}&year=${selectedYear}`
        })
        return response;
    }

}

export default new Services()