import React from 'react'
import { Heading, Button, Image, VStack, Text,Icon, Center } from '@chakra-ui/react'
import { FiRefreshCw } from 'react-icons/fi';

type Props = {
    title : string, message : string, onRetry : () => void, illustrator: string
}

const Error = ({ title, message, onRetry, illustrator }: Props) => {
	return (
        <Center height='100vh'>
		<VStack >
			<Image alignSelf="center" m={0} src={illustrator} width={600}></Image>
			<Heading alignSelf="center" mt={10}>{title}</Heading>
			<Text alignSelf="center" textAlign="center" fontSize="lg">{message}</Text>
			<Button onClick={onRetry}>
                <Icon as={FiRefreshCw} mr={2}/>
				Retry
			</Button>
		</VStack >
        </Center>
	)
}
export default Error;