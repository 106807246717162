import { Box, IconButton, Input, Text, useDisclosure } from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import paths, { pageTitles } from "../../utils/paths";
import GaraazGeneralTable from "../../components/GaraazGeneralTable";
import { useEffect, useState } from "react";
import services from "../SparePart/services";
import { BiSave } from "react-icons/bi";
import ActionCell from "./components/ActionCell";
import useNotification from "../../utils/useNotification";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const toast = useNotification();
  const [uniqueUPNs, setUniqueUPNs] = useState([]);
  const [currentUPNs, setcurrentUPNs] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const response = await services.getUniqueUPNs();
    setcurrentUPNs(
      response.map((item: string) => ({
        oldUPN: item,
        newUPN: item,
      }))
    );
    setUniqueUPNs(
      response.map((item: string) => ({
        oldUPN: item,
        newUPN: item,
      }))
    );
  }

  const columns = [
    {
      header: "UPN",
      accessor: "newUPN",
    },
    {
      header: "Action",
      renderCell: (row: any) => {
        return (
          <ActionCell
            row={row}
            handleConformEditUPN={handleEditUPN}
          ></ActionCell>
        );
      },
      width: "5rem",
    },
  ];

  const editedColumns = [
    {
      header: "Old UPN",
      accessor: "oldUPN",
    },
    {
      header: "New UPN",
      accessor: "newUPN",
    },
  ];
  function handleEditUPN(oldUPN: string, newUPN: string) {
    setcurrentUPNs((oldcurrentUPNs: any) => {
      return oldcurrentUPNs.map((item: any) => {
        if (item.oldUPN === oldUPN) {
          return {
            ...item,
            newUPN: newUPN,
          };
        } else {
          return item;
        }
      });
    });
  }

  async function handleUPNChange() {
    try {
      const editedUPNs = currentUPNs.filter(
        (item: any) => item.oldUPN !== item.newUPN
      );
      if (editedUPNs.length === 0) {
        toast({
          description: "No Edited UPNs found",
          status: "error",
        });
        return;
      }
      const result = await services.postSparePartCompatibilityRequest({
        name: "EDIT_UPN_REQUEST",
        editUPNs: editedUPNs,
      });

      toast({
        description: "Edit UPN request submitted successfully",
        status: "success",
      });
      setcurrentUPNs(uniqueUPNs);
    } catch (error: any) {
      toast({
        description: error.response.data.errorMsg ?? "Something went wrong",
        status: "error",
      });
    }
  }

  return (
    <PageScaffold
      title={pageTitles.UPN}
      hasButton={true}
      buttonText={
        <IconButton
          position={"fixed"}
          right={4}
          aria-label="saveBtn"
          size="md"
          color={"white"}
          icon={<BiSave size={"1.5rem"} />}
          onClick={handleUPNChange}
        />
      }
    >
      {currentUPNs.some((item: any) => item.oldUPN !== item.newUPN) && (
        <Box mb={10}>
          <Text fontSize={"2xl"} mb={2}>
            Edited UPNs
          </Text>
          <GaraazGeneralTable
            data={currentUPNs.filter(
              (item: any) => item.oldUPN !== item.newUPN
            )}
            columns={editedColumns}
          ></GaraazGeneralTable>
        </Box>
      )}
      <Box>
        <GaraazGeneralTable
          data={currentUPNs}
          columns={columns}
        ></GaraazGeneralTable>
      </Box>
    </PageScaffold>
  );
};
export default Index;
