import { KeyboardEvent, useState, FormEvent, useRef, useEffect } from 'react';

import {
  Box,
  Text,
  HStack,
  LinkBox,
  LinkOverlay,
  Spinner,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from '@chakra-ui/react';

type Props = {
  setPriority: (value: number) => void;
  priority: number;
  isLoading?: boolean;
  isCreating?: boolean;
};

const PriorityButton = ({ isCreating = false, ...props }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [priority, setPriority] = useState('');
  const numberInputRef = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (isEditing) {
      numberInputRef.current.focus();
    }
  }, [isEditing]);

  const isValid = (priority: string) => {
    return priority && !isNaN(parseInt(priority)) && parseInt(priority) > 0;
  };

  function updatePriority() {
    setIsEditing(false);
    if (isValid(priority)) {
      props.setPriority(parseInt(priority));
    }
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      updatePriority();
    }
  };

  const handleEditPriority = (e: FormEvent) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handlePriorityChange = (
    valueAsString: string,
    valueAsNumber: number,
  ) => {
    setPriority(valueAsString);

    if (isValid(valueAsString)) {
      if (isCreating) {
        props.setPriority(valueAsNumber);
      }
    }
  };

  const handleOnBlur = () => {
    updatePriority();
  };

  return (
    <>
      {isEditing && (
        <HStack>
          <Box>
            <Text>Priority:</Text>
          </Box>
          <Box width='50%' onBlur={handleOnBlur} onKeyDown={handleKeyDown}>
            <NumberInput
              value={priority}
              onChange={handlePriorityChange}
              min={1}
            >
              <NumberInputField ref={numberInputRef} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
        </HStack>
      )}
      {!isEditing && (
        <LinkBox>
          <Box>
            {!props.isLoading ? (
              <LinkOverlay href='' onClick={handleEditPriority}>
                <Text>Priority: {props.priority}</Text>
              </LinkOverlay>
            ) : (
              <HStack>
                <Text>Priority:</Text>
                <Spinner size='xs' />
              </HStack>
            )}
          </Box>
        </LinkBox>
      )}
    </>
  );
};

export default PriorityButton;
