import { useEffect } from 'react';
import {
  Box,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Text,
  VStack,
  Center,
  Button,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormValues } from '../../../utils/types';
import { useVisit } from './VisitContext';
import { phoneNumberFormSchema as schema } from './schema';
import { showOtpPageType } from '../types';
import { useGetOTP } from '../queryHooks';
import constants from '../constants';
import Enums from '../enums'


type Props = {
  setShowOtpPage: (props: showOtpPageType) => void;
};

function PhoneNumberForm(props: Props){
  const visitId = useVisit();
  const {
    mutate: getOTP,
    isLoading: gettingOTP,
    isSuccess: OTPSuccess,
  } = useGetOTP();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (OTPSuccess) {
      props.setShowOtpPage({
        showOtpPage: true,
        phoneNumber: getValues(constants.phoneNumber),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OTPSuccess]);

  const onSubmit = (values: FormValues) => {
    getOTP({ phoneNumber: values.phoneNumber, visitId , mobileNumberPrefix: Enums.MOBILE_NUMBER_PREFIX});
  };

  return (
    <VStack>
      <Text variant='mutedCenter'>Enter your phone number to get OTP</Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Center>
          <Box>
            <FormControl isInvalid={!!errors.phoneNumber}>
              <InputGroup>
                <InputLeftAddon children='+91' bgColor='white' mr='3' />
                <Input
                  type='number'
                  placeholder='phone number'
                  {...register(constants.phoneNumber)}
                />
                <InputRightElement border='none'>
                  <button type='reset'>{<MdClose />}</button>
                </InputRightElement>
              </InputGroup>
              {errors.phoneNumber && (
                <FormErrorMessage>
                  {errors.phoneNumber.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </Box>
        </Center>
        <Center mt='4'>
          <Button isLoading={gettingOTP} type='submit'>
          Get OTP
          </Button>
        </Center>
      </form>
    </VStack>
  );
};

export default PhoneNumberForm;
