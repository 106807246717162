import React from 'react';
import withRouter from '../HOCs/withRouter';
import Error from './Error';


export class ErrorBoundary extends React.Component<any, any> {
    constructor(props: any) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error: any) {
    
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error: any, errorInfo: any) {
      // You can also log the error to an error reporting service
  
    }
  
    render() {

      if (this.state.hasError) {
        // You can render any custom fallback UI
        return( <Error
          onRetry={() => {
            this.props.navigate('/');
            this.setState({hasError: false})
          }}
          title="Something went wrong"
          message='We are working on this, please try again!'
          illustrator='/images/serverError.svg'
        />
        );
      }
  
      return this.props.children; 
    }
  }
  

export default withRouter(ErrorBoundary);
