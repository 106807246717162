import {forwardRef, ForwardedRef, FormEvent} from 'react'
import { Box } from '@chakra-ui/react';

type Props = {
    onChange: (e: FormEvent<HTMLInputElement>) => void
};

const FileInput = forwardRef((props: Props, ref: ForwardedRef<HTMLInputElement>) => {
    return (
        <Box display='none'>
        <input
          accept='image/*, video/*'
          type='file'
          ref={ref}
          onChange={props.onChange}
        />
      </Box>
    );
});

export default FileInput;