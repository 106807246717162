import { Flex, Spinner, Text } from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import { pageTitles } from "../../utils/paths";
import StatsCard from "../../components/StatsCard";
import { useDailyLiveDashboard } from "./queryHooks";

const Index = () => {
  const { data: liveDashboard, isLoading: loading } = useDailyLiveDashboard();
  return (
    <PageScaffold title={pageTitles.LiveDashboard}>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" minH="100vh">
          <Spinner size="xl" color="blue.500" />
        </Flex>
      ) : (
        <Flex flexWrap="wrap" gap={6}>
          {Object.entries(liveDashboard).length === 0 ? (
            <Text textAlign="center" w="100%">
              No data available today
            </Text>
          ) : (
            Object.entries(liveDashboard).map(([key, value]) => (
              <StatsCard
                key={key}
                title={key.toUpperCase()}
                value={String(value)}
              />
            ))
          )}
        </Flex>
      )}
    </PageScaffold>
  );
};

export default Index;
