import globalConstant from "../../utils/constants";
import { authAxios as axios } from "../../config/axios";
import endpoints from "../../utils/endpoints";
import { getBrandsNameSorted } from "../../utils/sortByName";

class Services {
  async getSupplierDetails(supplierId: string) {
    const response = await axios({
      method: globalConstant.GET,
      url: `${endpoints.getSupplierInfo(supplierId)}`,
    });
    return response.data;
  }
  async deleteSupplierBranchById(
    supplierId: string | undefined,
    branchId: string | undefined
  ) {
    const response = await axios({
      method: "DELETE",
      url: endpoints.deleteSupplierBranchById(supplierId, branchId),
      headers: {
        Authorization: localStorage.getItem("MC_SUPER_ADMIN"),
      },
    });
    return response.data;
  }

  async addMedia(payload: any) {
    const response = await axios({
      method: globalConstant.POST,
      url: endpoints.addMedia,
      data: payload,
      headers: {
        Authorization: localStorage.getItem("MC_SUPER_ADMIN"),
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }

  async getMedia(mediaId: string) {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getMedia(mediaId),
      headers: {
        Authorization: localStorage.getItem("MC_SUPER_ADMIN"),
      },
    });
    return response.data;
  }

  async getBrandsInfo() {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getBrands,
    });
    const sortedBrands = getBrandsNameSorted(response.data);
    return sortedBrands;
  }

  async getPartCategoryInfo() {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getPartCategory,
    });
    return response.data;
  }
}

export default new Services();
