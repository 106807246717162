import { useMemo, useCallback , useEffect} from 'react';

import { IconButton, Box, useDisclosure } from '@chakra-ui/react';
import { RiDeleteBin6Line } from 'react-icons/ri';

import MediaComponent from '../../../components/MediaComponent';
import CardScaffold from '../../../components/CardScaffold';
import SubmitAndCloseButton from '../../../components/SubmitAndCloseButton';
import ModalComponent from '../../../components/ModalComponent';
import Enums from '../Enums';

import { Ads } from '../types';
import { useDeleteAdvert } from '../queryHooks';




const AdsCard = (props: Ads) => {
  const { onClose, isOpen, onOpen } = useDisclosure();
  const {
    mutate: deleteAdvert,
    isLoading: deletingAdvert,
    isSuccess: advertDeleted,
  } = useDeleteAdvert();

  useEffect(() => {
    if (advertDeleted) {
      onClose();
    }
  }, [advertDeleted, onClose]);


  const handleDeleteBanner = useCallback(() => {
    deleteAdvert({ advertId: props._id });
  },[deleteAdvert, props._id])

  const bottomButton = useMemo(() => {
    return (
      <SubmitAndCloseButton
        isLoading={deletingAdvert}
        onSubmit={handleDeleteBanner}
        onClose={onClose}
        variant='delete'
      />
    );
  }, [handleDeleteBanner, deletingAdvert, onClose]);

  return (
    <>
      <CardScaffold
        media={
          <MediaComponent
            path={props.url}
            isVideo={props.url.endsWith('mp4')}
          />
        }
        rightMenu={
          <>
            <IconButton
              onClick={onOpen}
              isRound
              variant='ghost'
              size='md'
              colorScheme='red'
              icon={<RiDeleteBin6Line />}
              aria-label={''}
            />
          </>
        }
      />
      <ModalComponent
        button={bottomButton}
        isOpen={isOpen}
        title={Enums.DELETE_ADVERTS}
        onClose={onClose}
      >
        <Box>This will delete Advert from the Customer Mobile Apps. Are you sure you want to delete?</Box>
      </ModalComponent>
    </>
  );
};
export default AdsCard;
