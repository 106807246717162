export const primaryColors = {
  primary_100: "#1E293B",
};

export const secondaryColors = {
  secondary_100: "#EDF2F7",
};

export const successColors = {
  success_100: "#47BB78",
  success_110: "#37A169",
};
