import { Box, Select } from '@chakra-ui/react';

interface Props {
    onSelect: (year: string) => void;
}

const SelectYear = (props: Props) => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    return (
        <Box width="100px" height="40px">
            <Select textColor={"#FFFFFF"} defaultValue={currentYear} onChange={e => props.onSelect(e.target.value)}>
                <option value={currentYear}>{currentYear}</option>
                <option value={previousYear}>{previousYear}</option>
            </Select>
        </Box>
    );
};

export default SelectYear;