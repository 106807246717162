import { StyleFunctionProps } from '@chakra-ui/styled-system';

export const TextStyle = {
  baseStyle: () => ({
    fontSize: { base: '16px', lg: '1.2vw' },
  }),

  size: {},
  variants: {
    mutedCenter: (props: StyleFunctionProps) => ({
      color: props.theme.colors.muted,
      textAlign: 'center',
    }),
    boldNormal: (props: StyleFunctionProps) => ({
      fontWeight: 'bold',
      //textAlign: 'center'
    }),
    smallBoldNormal: (props: StyleFunctionProps) => ({
      fontWeight: 'bold',
      fontSize: '1.15vw',
    }),
    nav:  (props: StyleFunctionProps) => ({
      fontSize: { base: '16px', lg: '1.1vw' },
    }),
    whiteBoldNormal: (props: StyleFunctionProps) => ({
      fontWeight: 'bold',
      textAlign: 'center',
      color: props.theme.colors.white,
    }),
  },
  defaultProps: {},
};
