import PageScaffold from "../../components/PageScaffold";
import { pageTitles } from "../../utils/paths";
import GaraazTable, { COLUMN_TYPES } from "../../components/GaraazTable";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import BasicProfileDetails from "./components/BasicProfileDetails";
import { Button } from "@chakra-ui/react";
import AddNewCustomer from "./components/AddNewCustomer";
import { secondaryColors } from "../../utils/designSystem";

const Index = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const TableHeading = [
    {
      header: "Customer Name",
      accessor: "name",
      type: COLUMN_TYPES.FULLNAME,
    },
    {
      header: "Mobile Number",
      accessor: "mobileNumber",
      type: COLUMN_TYPES.MOBILE,
    },
    {
      header: "Customer code",
      accessor: "code",
    },
    {
      header: "Blocked",
      accessor: "actions",
      type: COLUMN_TYPES.ACTIONS,
      isSortable: false,
    },
  ];

  const row = [10, 20, 30, 50, 100, 150, 500];
  const value = 500;

  const handleAddCustomer = () => {
    navigate(`${pathname}/onboarding`);
  };

  const handleCustomerRowClick = (row: any) => {
    navigate(`${pathname}/${row.mobileNumber}/overview`);
  };

  return (
    <Routes>
      <Route
        path={"/*"}
        element={
          <>
            <PageScaffold
              title={pageTitles.customers}
              hasButton={true}
              buttonText={
                <Button
                  position={"fixed"}
                  right={4}
                  border={`1px solid ${secondaryColors.secondary_100}`}
                  padding={"7px 10px 7px 10px"}
                  onClick={handleAddCustomer}
                >
                  + Add
                </Button>
              }
            >
              <GaraazTable
                columns={TableHeading}
                url={`${process.env.REACT_APP_BASE_URL}/v2/customers?isXpress=true`}
                limits={row}
                defaultRowLimit={value}
                handleRowClick={handleCustomerRowClick}
              />
            </PageScaffold>
          </>
        }
      />
      <Route path="/onboarding" element={<AddNewCustomer />} />
      <Route
        path={`:mobileNumber/overview`}
        element={<BasicProfileDetails />}
      />
    </Routes>
  );
};
export default Index;
