import { Flex, Heading } from "@chakra-ui/react";

const GaraazBasicInfoStack = (props: { onClickIconButton?: () => void }) => {
  return (
    <Flex
      mb={8}
      borderBottom={"2px solid lightgrey"}
      paddingBottom={"5px"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Heading as="h2" size="md" mb={2}>
        Basic Info
      </Heading>
    </Flex>
  );
};

export default GaraazBasicInfoStack;
