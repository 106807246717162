import { Box, Flex, FormControl, Input, Select } from "@chakra-ui/react";
import GaraazFormLabel from "../../../components/GarrazFormLabel";
import { useEffect, useState } from "react";
import services from "../services";
import useNotification from "../../../utils/useNotification";

type AddNewSparePartProps = {
  partData: any;
  UPNSelectRef: React.MutableRefObject<any>;
  partNumberInputRef: React.MutableRefObject<any>;
  isCompatibilityVisible: boolean;
};

const AddNewSparePart: React.FC<AddNewSparePartProps> = ({
  partData,
  UPNSelectRef,
  partNumberInputRef,
  isCompatibilityVisible,
}) => {
  const [selectedUPN, setSelectedUPN] = useState(partData?.UPN);
  const [selectedPartNumber, setSelectedPartNumber] = useState(
    partData?.number
  );
  const [uniqueUPNs, setUniqueUPNs] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const response = await services.getUniqueUPNs();
    setUniqueUPNs(response);
  }

  return (
    <Box>
      <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
        <FormControl width={"25%"} id="UPN" flex={1}>
          <GaraazFormLabel text="UPN" isRequired={true} />
        </FormControl>
        <Flex width={"75%"}>
          <FormControl flex={1}>
            <Select
              ref={UPNSelectRef}
              placeholder={"Select UPN"}
              name="UPN"
              value={selectedUPN}
              onChange={(e) => {
                setSelectedUPN(e.target.value);
              }}
              isDisabled={isCompatibilityVisible}
            >
              {uniqueUPNs.map((UPN: any, index: any) => (
                <option value={UPN} key={`${UPN}-${index}`}>
                  {UPN}
                </option>
              ))}
            </Select>
          </FormControl>
        </Flex>
      </Flex>
      <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
        <FormControl width={"25%"} id="Part Number" flex={1}>
          <GaraazFormLabel text="Part Number" isRequired={true} />
        </FormControl>
        <Flex width={"75%"}>
          <FormControl flex={1}>
            <Input
              ref={partNumberInputRef}
              type="text"
              placeholder="Enter Part Number"
              name="number"
              value={selectedPartNumber}
              onChange={(e) => {
                setSelectedPartNumber(e.target.value);
              }}
              isDisabled={isCompatibilityVisible}
            />
          </FormControl>
        </Flex>
      </Flex>
    </Box>
  );
};

export default AddNewSparePart;
