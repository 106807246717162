import { IconButton } from "@chakra-ui/react";

type GaraazIconButtonProps = {
  onClick: () => void;
  icon: any;
  style?: any;
  isDisabled?: boolean;
};

const GaraazIconButton: React.FC<GaraazIconButtonProps> = ({
  onClick,
  icon,
  style,
  isDisabled,
}) => {
  return (
    <IconButton
      aria-label={""}
      icon={icon}
      onClick={onClick}
      width={7}
      height={7}
      minW={7}
      backgroundColor={"#EDF2F6"}
      color={"#4A5567"}
      style={style}
      _hover={{ backgroundColor: "#E2E8F0 !important" }}
      boxShadow={"none"}
      isDisabled={isDisabled}
    ></IconButton>
  );
};
export default GaraazIconButton;
