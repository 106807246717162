import {
  Box,
  IconButton,
  Button,
  useDisclosure,
  Flex,
  FormControl,
  Select,
  Input,
  Text,
  Icon,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import services from "../services";
import services1 from "../../SparePart/services";
import PageScaffold from "../../../components/PageScaffold";
import paths, { pageTitles } from "../../../utils/paths";
import { BiSave } from "react-icons/bi";
import useNotification from "../../../utils/useNotification";
import AddNewCar from "./AddCar";
import GaraazGeneralTable from "../../../components/GaraazGeneralTable";
import { MdDelete } from "react-icons/md";
import PopupModal from "../../../components/PopupModal";
import ActionCell from "./ActionCell";
import GaraazFormLabel from "../../../components/GarrazFormLabel";
import VerifiedIcon from "@mui/icons-material/Verified";

const EditCompatibility = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const toast = useNotification();
  const [carData, setCarData] = useState<any>(null);
  const [initialCarParts, setInitialCarPart] = useState<any>({});
  const [currentCarParts, setCurrentCarParts] = useState<any>({});

  const [isCompatibilityVisible, setIsCompatibilityVisible] = useState(false);
  const [selectedUPN, setSelectedUPN] = useState("");
  const [selectedPartNumber, setSelectedPartNumber] = useState("");
  const [uniqueUPNs, setUniqueUPNs] = useState([]);

  const generationSelectRef = useRef<any>(null);
  const nameRef = useRef<any>(null);
  const descriptionRef = useRef<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (state.carId) {
      fetchCarData();
    }
  }, [state]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const response = await services1.getUniqueUPNs();
    setUniqueUPNs(response);
  }

  async function fetchCarData() {
    setIsCompatibilityVisible(true);
    const response = await services.getCarById(state.carId);
    setCarData(response);
    setInitialCarPart(response?.carParts);
    setCurrentCarParts(response?.carParts);
  }

  async function handleSaveCompatibility() {
    try {
      const addedParts: any[] = [];
      const deletedParts: any[] = [];
      const editedParts: any[] = [];

      // Find added parts
      Object.keys(currentCarParts).forEach((upn) => {
        if (!initialCarParts.hasOwnProperty(upn)) {
          addedParts.push({
            UPN: upn,
            partNumber: currentCarParts[upn].partId.number,
          });
        }
      });

      // Find deleted parts
      Object.keys(initialCarParts).forEach((upn) => {
        if (!currentCarParts.hasOwnProperty(upn)) {
          deletedParts.push({
            UPN: upn,
            partNumber: initialCarParts[upn].partId.number,
          });
        }
      });

      // Find edited parts
      Object.keys(currentCarParts).forEach((upn) => {
        if (initialCarParts.hasOwnProperty(upn)) {
          const initialDetails = initialCarParts[upn];
          const currentDetails = currentCarParts[upn];

          if (
            initialDetails.partId.number !== currentDetails.partId.number ||
            initialDetails.partLink !== currentDetails.partLink
          ) {
            editedParts.push({
              UPN: upn,
              partNumber: currentDetails.partId.number,
            });
          }
        }
      });

      if (
        addedParts.length > 0 ||
        editedParts.length > 0 ||
        deletedParts.length > 0
      ) {
        await services.postSparePartCompatibilityRequest({
          name: "UPSERT_CAR_REQUEST",
          description: carData?.description ?? descriptionRef.current.value,
          generation: carData?.generation ?? generationSelectRef.current.value,
          carName: carData?.name ?? nameRef.current.value,
          addSpareParts: addedParts,
          removeSpareParts: deletedParts,
          editSpareParts: editedParts,
        });

        toast({
          status: "success",
          description: "Car Compatibility Request Submitted",
        });

        setCurrentCarParts(initialCarParts);
        navigate(`/${paths.car}`);
      } else {
        toast({
          description: "No Changes in Spare Parts found",
          status: "error",
        });
      }
    } catch (error: any) {
      toast({ description: error?.response?.data?.errorMsg, status: "error" });
    }
  }

  async function AddCarPart(UPN: string, number: string) {
    setCurrentCarParts((prevParts: any) => ({
      ...prevParts,
      [UPN]: {
        partId: { UPN: UPN, number: number },
        partLink: "",
      },
    }));
  }

  async function deleteCarPart(UPN: string) {
    setCurrentCarParts((prevParts: any) => {
      const updatedParts = { ...prevParts };
      delete updatedParts[UPN];
      return updatedParts;
    });
  }

  async function editCarPart(UPN: string, newPartNumber: string) {
    setCurrentCarParts((prevParts: any) => ({
      ...prevParts,
      [UPN]: {
        ...prevParts[UPN],
        partId: { UPN: UPN, number: newPartNumber },
      },
    }));
  }

  async function handleUPNAndPNSearchFormmSubmit(
    e: React.FormEvent<HTMLFormElement>
  ) {
    e.preventDefault();
    if (
      generationSelectRef.current.value.trim() === "" ||
      nameRef.current.value.trim() === ""
    )
      return;

    const response = await services.getCarByQuery(
      `?generation=${generationSelectRef.current.value}&name=${nameRef.current.value}&description=${descriptionRef.current.value}&carPartsPopulated=true`
    );
    if (response.data[0]) {
      const carById = await services.getCarById(response.data[0]._id);
      setCarData(carById);
      setCurrentCarParts(carById?.carParts);
      setInitialCarPart(carById?.carParts);
    }
    setIsCompatibilityVisible(true);
  }

  const columns = [
    {
      header: "UPN",
      renderCell: (row: any) => {
        return (
          <Box display={"flex"} alignItems={"center"}>
            <Text fontSize={"xs"}>{row.partId.UPN}</Text>
            {row.isVerified && (
              <Icon
                as={VerifiedIcon}
                color={"green"}
                ml={2}
                fontSize={"lg"}
              ></Icon>
            )}
          </Box>
        );
      },
    },
    {
      header: "Number",
      accessor: "partId.number",
    },

    {
      header: "Part Name",
      accessor: "partId.partName",
    },
    {
      header: "MRP",
      accessor: "partId.mrp",
    },
    {
      header: "Action",
      renderCell: (row: any) => {
        return (
          <ActionCell
            row={row}
            uniqueUPNs={uniqueUPNs}
            onEditOk={editCarPart}
            onDelete={deleteCarPart}
          ></ActionCell>
        );
      },
    },
  ];

  return (
    <PageScaffold
      title={state.carId ? pageTitles.editCar : pageTitles.addCar}
      hasButton={true}
      buttonText={
        <IconButton
          position={"fixed"}
          right={4}
          aria-label="saveBtn"
          size="md"
          color={"white"}
          icon={<BiSave size={"1.5rem"} />}
          onClick={handleSaveCompatibility}
          isDisabled={!isCompatibilityVisible}
        />
      }
    >
      <form onSubmit={handleUPNAndPNSearchFormmSubmit}>
        {(carData || !state.carId) && (
          <AddNewCar
            generationSelectRef={generationSelectRef}
            carData={carData}
            nameRef={nameRef}
            isCompatibilityVisible={isCompatibilityVisible}
            descriptionRef={descriptionRef}
          ></AddNewCar>
        )}
        <Button type="submit" isDisabled={isCompatibilityVisible}>
          Add
        </Button>
      </form>
      {isCompatibilityVisible && (
        <Box display={"flex"} width={"100%"} flexDirection={"column"}>
          <Box
            display={"flex"}
            mt={10}
            alignItems={"center"}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Text fontSize={"xl"} fontWeight={"bold"}>
              {" "}
              Spare Parts
            </Text>
            <Button
              _hover={{ backgroundColor: "#e9ecef !important" }}
              _focus={{ bg: "none", boxShadow: "none" }}
              size="sm"
              bg="none"
              color={"green.400"}
              onClick={onOpen}
            >
              + Add
            </Button>
          </Box>

          <Box width={"100%"} mt={2}>
            <GaraazGeneralTable
              data={Object.entries(currentCarParts).map(
                (entry: any) => entry[1]
              )}
              columns={columns}
            ></GaraazGeneralTable>
          </Box>
        </Box>
      )}

      <PopupModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setSelectedPartNumber("");
          setSelectedUPN("");
        }}
        bodyText={
          <>
            <Flex
              alignItems="center"
              justify="space-between"
              mb={4}
              width={"70%"}
            >
              <FormControl width={"25%"} id="UPN" flex={1}>
                <GaraazFormLabel text="UPN" isRequired={true} />
              </FormControl>
              <Flex width={"75%"}>
                <FormControl flex={1}>
                  <Select
                    placeholder={"Select UPN"}
                    name="UPN"
                    value={selectedUPN}
                    onChange={(e) => {
                      setSelectedUPN(e.target.value);
                    }}
                  >
                    {uniqueUPNs
                      .filter(
                        (UPN) =>
                          !Object.keys(currentCarParts).some(
                            (key) => key === UPN
                          )
                      )
                      .map((UPN: any, index: any) => (
                        <option value={UPN} key={`${UPN}-${index}`}>
                          {UPN}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Flex>
            </Flex>
            <Flex
              alignItems="center"
              justify="space-between"
              mb={4}
              width={"70%"}
            >
              <FormControl width={"25%"} id="Part Number" flex={1}>
                <GaraazFormLabel text="Part Number" isRequired={true} />
              </FormControl>
              <Flex width={"75%"}>
                <FormControl flex={1}>
                  <Input
                    type="text"
                    placeholder="Enter Part Number"
                    name="number"
                    value={selectedPartNumber}
                    onChange={(e) => {
                      setSelectedPartNumber(e.target.value);
                    }}
                  />
                </FormControl>
              </Flex>
            </Flex>
          </>
        }
        title={"Add Spare Part"}
        hasFooter={true}
        secondaryActionButtonText="Confirm"
        onCancel={() => {
          onClose();
          setSelectedPartNumber("");
          setSelectedUPN("");
        }}
        onOk={() => {
          AddCarPart(selectedUPN, selectedPartNumber);
          onClose();
          setSelectedPartNumber("");
          setSelectedUPN("");
        }}
        isOkButtonDisabled={
          selectedPartNumber.trim() === "" || selectedUPN.trim() === ""
        }
      ></PopupModal>
    </PageScaffold>
  );
};

export default EditCompatibility;
