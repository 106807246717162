import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { setupPublicAxios, setupAuthAxios } from "./config/axios";
import { UserDetailsProvider } from "./Contexts/userDetailsContext";

import LiveDashboard from "./views/LiveDashboard";
import Dashboards from "./views/Dashboards";
import Subscription from "./views/Subscriptions";
import Feedbacks from "./views/Feedbacks";
import Suppliers from "./views/Suppliers";
import Grazeons from "./views/Grazeons";
import Customers from "./views/Customers";
import BoodmoModel from "./views/BoodmoModel";
import SparePart from "./views/SparePart";
import Car from "./views/Car";
import UPNS from "./views/UPNS";

import CustomerMetrics from "./views/CustomerMetrics";
import LoginPage from "./views/Login";
import BannerManagement from "./views/MediaManagement";
import MobileConfiguration from "./views/MobileConfiguration";
import AdsManagement from "./views/AdsManagement";
import Intents from "./views/Intents";

import Layout from "./components/Layout";
import globalConstant from "./utils/constants";
import paths from "./utils/paths";

setupPublicAxios(process.env.REACT_APP_BASE_URL);

export const App = () => {
  useEffect(() => {
    if (localStorage.getItem(globalConstant.MC_SUPER_ADMIN)) {
      const authJSON = localStorage.getItem(globalConstant.MC_SUPER_ADMIN);
      if (authJSON) {
        setupAuthAxios(process.env.REACT_APP_BASE_URL, authJSON);
      }
    }
  }, []);
  return (
    <Routes>
      <Route
        path={paths.home}
        element={
          <ProtectedRoutes>
            <Layout />
          </ProtectedRoutes>
        }
      >
        <Route index element={<Dashboards />} />
        <Route path={paths.liveDashboard} element={<LiveDashboard />} />

        <Route path={`${paths.customers}/*`} element={<Customers />} />
        <Route path={paths.subscriptions} element={<Subscription />} />
        <Route path={paths.feedbacks} element={<Feedbacks />} />

        <Route path={`${paths.suppliers}/*`} element={<Suppliers />} />
        <Route path={`${paths.grazeons}/*`} element={<Grazeons />} />
        <Route path={`${paths.boodmoModel}`} element={<BoodmoModel />}></Route>
        <Route
          path={`${paths.sparePart}/*`}
          element={<SparePart></SparePart>}
        ></Route>
        <Route path={`${paths.car}/*`} element={<Car></Car>}></Route>
        <Route path={`${paths.UPNs}/*`} element={<UPNS></UPNS>}></Route>

        <Route path={paths.customerMetrics} element={<CustomerMetrics />} />
        <Route path={paths.mediaManagement} element={<BannerManagement />} />
        <Route
          path={paths.mobileConfigurations}
          element={<MobileConfiguration />}
        />
        <Route path={paths.adsManagement} element={<AdsManagement />} />
        <Route path={paths.intents} element={<Intents />} />
      </Route>
      <Route path={paths.login} element={<LoginPage />} />
    </Routes>
  );
};

function ProtectedRoutes({ children }: { children: JSX.Element }) {
  let location = useLocation();

  if (!localStorage.getItem(globalConstant.MC_SUPER_ADMIN))
    return <Navigate to={paths.login} state={{ from: location }} replace />;

  return <UserDetailsProvider>{children}</UserDetailsProvider>;
}
