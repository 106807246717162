import endpoints from '../../utils/endpoints';
import { publicAxios as axios } from '../../config/axios';
import globalConstant from '../../utils/constants';


class Services {
    async getOTP(payload: { visitId: string, phoneNumber: string, mobileNumberPrefix : string}) {
        const response = await axios({
            method: globalConstant.GET,
            url: endpoints.getOTP(payload.visitId, payload.phoneNumber, payload.mobileNumberPrefix),
        })
        return response.data;
    }

    async verifyOTP(payload: { OTP: string, phoneNumber: string, mobileNumberPrefix: string}) {
        const response = await axios({
            method: globalConstant.GET,
            url: endpoints.verifyOTP(payload.phoneNumber, payload.OTP, payload.mobileNumberPrefix),
        })
        return response.data;
    }

    async getVisitId() {
        const response = await axios({
            method: globalConstant.POST,
            url: endpoints.visitId,
            data: { source: globalConstant.WEB }
        })
        return response.data
    }


}

export default new Services()