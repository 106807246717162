import { Text, Link, } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

const UngroupedNav = ({
    path,
    name
}: {
    path: string;
    name: string;
}) => {
    return (
        <Link as={NavLink} to={path}>
            <Text ml='6' variant='smallBoldNormal'>
                {name}
            </Text>
        </Link>
    );
};

export default UngroupedNav;