enum constants {
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
    WEB = 'WEB',
    MC_SUPER_ADMIN = 'MC_SUPER_ADMIN',
    MISSION_CONTROL = 'MISSION_CONTROL',
    AUDITOR = 'AUDITOR',
    METRICS = 'Metrics',
    LIVE_DASHBOARD = 'Live Dashboard',
    Error = 'error',
    Success = 'success',
    ERROR_MESSAGE = 'Error occured, please try again',
    TEXT = 'text',
    TOAST_TIMEOUT = '5000',
    MEDIA = 'media',
    CUSTOMER_METRICS_DEFINITIONS = 'Customer Metrics Definitions',
    CUSTOMER_METRICS_INFO = '1. CUSTOMER_ACQUISITION_RATE: Percentage of new customers acquired in the selected period. \n' +
    '2. CUSTOMER_RETENTION_RATE: Percentage of retained customers in the selected period. \n' +
    '3. CUSTOMER_CHURN_RATE: Percentage of customers discontinuing subscription in the selected period.  \n' +
    '4. REPEAT_PURCHASE_RATE_30: Percentage of customers placing 30 or more orders in the selected period. \n' +
    '5. REPEAT_PURCHASE_RATE_15: Percentage of customers placing 15 or more orders in the selected period. \n' +
    '6. REPEAT_PURCHASE_RATE_5: Percentage of customers placing 5 or more orders in the selected period. \n' +
    '7. AVERAGE_CUSTOMER_ORDER_VALUE: Average value of order given by a single customer. \n' +
    '8. TOTAL_APP_USERS: Total number of customers using Garaaz mobile app till the end of selected period.  \n' +
    '9. XPRESS: Total number of Xpress customers till the end of selected period. \n' +
    '10. NEW: Number of new Xpress customers added in the selected period. \n' +
    '11. UNIQUE: Number of unique customers placing order in the selected period. \n' +
    '12. EXPIRED: Number of customers having their subscription plan expired in the selected period. \n' +
    '13. CHURN: Number of existing customers who haven\'t placed any order in the selected period. \n' +
    '14. MORE_30: Number of customers placing 30 or more orders in the selected period. \n' +
    '15. MORE_15: Number of customers placing 15 or more orders in the selected period. \n' +
    '16. MORE_5: Number of customers placing 5 or more orders in the selected period. \n' +
    '17. HIGHEST_SPENDING: Name of the customer generating the maximum order value in the selected period. \n' +
    '18. MOST_FREQUENT: Name of the customer generating the maximum number of orders in the selected period. \n'
}

export default constants;