import { StyleFunctionProps } from '@chakra-ui/styled-system';

export const ButtonStyle = {
  size: {
    md: {
      padding: { base: 12, lg: 6 },
    },
  },
  variants: {
    normal: (props: StyleFunctionProps) => ({
      bg: props.theme.colors.primary,
      color: props.theme.colors.white,
      ':hover': {
        bg: props.theme.colors.muted,
        boxShadow: 'md',
      },
      ':focus': {
        bg: props.theme.colors.tertiary,
        boxShadow: 'md',
      },
      ':disabled': {
        bg: props.theme.colors.muted,
        ':hover': {
          bg: props.theme.colors.muted,
        }
      },
    }),
    delete: (props: StyleFunctionProps) => ({
      bg: props.theme.colors.secondary,
      color: props.theme.colors.white,
      ':hover': {
        bg: props.theme.colors.red[700],
        boxShadow: 'md',
      },
      ':focus': {
        bg: props.theme.colors.red[700],
        boxShadow: 'md',
      },
      ':disabled': {
        bg: props.theme.colors.muted,
        ':hover': {
          bg: props.theme.colors.muted,
        }
      },
    }),
    noBg: (props: StyleFunctionProps) => ({
      bg: props.theme.colors.transparent,
      color: props.theme.colors.blue[600],
      ':hover': {
        bg: props.theme.colors.transparent,
      },
      ':focus': {
        bg: props.theme.colors.transparent,
      },
      ':disabled': {
        bg: props.theme.colors.muted,
      },
    }),
    
  },
  defaultProps: {
    size: 'md',
    variant: 'normal',
  },
};
