import { Flex, Spinner } from '@chakra-ui/react';
import { useState } from 'react';
import PageScaffold from "../../components/PageScaffold";
import { pageTitles } from '../../utils/paths';
import StatsCard from '../../components/StatsCard';
import { useGetMonthlyMetrics } from './queryHooks';
import globalConstant from '../../utils/constants';

const Index = () => {
    const currentMonth = new Date().toLocaleString('default', { month: 'long' });
    const currentYear = new Date().getFullYear().toString();
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const { data: monthlyMetrics, isLoading: loading } = useGetMonthlyMetrics(selectedMonth, selectedYear);
    let metrics;
    if (monthlyMetrics) {
        metrics = Object.keys(monthlyMetrics).filter(metric => !["_id", "updatedAt", "createdAt", "month", "year", "__v"].includes(metric));
    }
    return (
        <PageScaffold
            title={pageTitles.customerMetrics}
            selectMonth={setSelectedMonth}
            selectYear={setSelectedYear}
            popupModalTitle={globalConstant.CUSTOMER_METRICS_DEFINITIONS}
            popupModalText={globalConstant.CUSTOMER_METRICS_INFO}
            icon={true}
        >
            {loading ? (
                <Flex justifyContent="center" alignItems="center" minH="100vh">
                    <Spinner size="xl" color="blue.500" />
                </Flex>
            ) : (
                <Flex flexWrap='wrap' gap={6}>
                    {metrics && metrics.map((metric, index) => (
                        <StatsCard
                            key={index.toString()}
                            title={metric.toUpperCase()}
                            value={monthlyMetrics[metric]}
                        />
                    ))}
                </Flex>
            )}
        </PageScaffold>
    )
}

export default Index;