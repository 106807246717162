import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  ModalFooter,
} from "@chakra-ui/react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  hasFooter?: boolean;
  bodyText: any;
  title: string;
  onCancel?: () => void;
  onOk?: () => void;
  secondaryActionButtonText?: string;
  isOkButtonDisabled?: boolean;
}

const PopupModal = (props: Props) => {
  return (
    <Modal size="4xl" isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div>
            <p style={{ whiteSpace: "pre-wrap" }}>{props.bodyText}</p>
          </div>
        </ModalBody>
        {props.hasFooter && (
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={props.onCancel}>
              Cancel
            </Button>
            <Button
              variant="ghost"
              onClick={props.onOk}
              isDisabled={props.isOkButtonDisabled}
            >
              {" "}
              {props.secondaryActionButtonText}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default PopupModal;
