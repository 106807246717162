import { useQuery } from "@tanstack/react-query";
import services from "./services";
import { queryEnum } from "../Customers/constants";

export const useGetSupplierInfo = (supplierId: any) => {
  return useQuery([supplierId], () => services.getSupplierDetails(supplierId));
};

export const useGetBrandsInfo = () => {
  return useQuery([queryEnum.BRANDS_INFO], services.getBrandsInfo, {
    staleTime: 10 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

export const useGetPartCategoryInfo = () => {
  return useQuery(
    [queryEnum.PART_CATEGORY_INFO],
    services.getPartCategoryInfo,
    {
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  );
};
