import {
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React from "react";

import { supplierFormData } from "./AddSupplier";

type BankingDetailsFormSupplierProps = {
  formData: supplierFormData;
  setFormData: React.Dispatch<React.SetStateAction<supplierFormData>>;
};

const BankingDetailsFormSupplier: React.FC<BankingDetailsFormSupplierProps> = ({
  formData,
  setFormData,
}) => {
  const handleBankingDetailsChange = (
    second:
      | "accountNumber"
      | "bankName"
      | "bankBranchAddress"
      | "ifsc"
      | "bankBranchName"
      | "upi",

    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setFormData((previousData) => {
      const newData = {
        ...previousData,
        bankingDetails: {
          ...previousData.bankingDetails,
          [second]: e.target.value,
        },
      };
      return newData;
    });
  };

  return (
    <>
      <Container maxW="-moz-fit-content" p={0}>
        <Flex align="start" mb={4} width="100%">
          <FormControl flex="1" mr={4}>
            <FormLabel
              style={{
                fontSize: "12px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              htmlFor={"Account Number"}
              color="#64748b"
            >
              Account Number
            </FormLabel>
            <Input
              value={formData.bankingDetails.accountNumber}
              type="text"
              id={"Account Number"}
              placeholder={`Enter Account Number`}
              onChange={(e) => {
                handleBankingDetailsChange("accountNumber", e);
              }}
            />
          </FormControl>
          <FormControl flex="1">
            <FormLabel
              style={{
                fontSize: "12px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              htmlFor={"Bank Name"}
              color="#64748b"
            >
              Bank Name
            </FormLabel>
            <Input
              value={formData.bankingDetails.bankName}
              type="text"
              id={"Bank Name"}
              placeholder={`Enter Bank Name`}
              onChange={(e) => {
                handleBankingDetailsChange("bankName", e);
              }}
            />
          </FormControl>
        </Flex>

        <Flex align="start" mb={4} width="100%">
          <FormControl flex="1" mr={4}>
            <FormLabel
              style={{
                fontSize: "12px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              htmlFor={"Bank Branch Address"}
              color="#64748b"
            >
              Bank Branch Address
            </FormLabel>
            <Input
              value={formData.bankingDetails.bankBranchAddress}
              type="text"
              id={"Bank Branch Address"}
              placeholder={`Enter Bank Branch Address`}
              onChange={(e) => {
                handleBankingDetailsChange("bankBranchAddress", e);
              }}
            />
          </FormControl>
          <FormControl flex="1">
            <FormLabel
              style={{
                fontSize: "12px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              htmlFor={"IFSC"}
              color="#64748b"
            >
              IFSC
            </FormLabel>
            <Input
              value={formData.bankingDetails.ifsc}
              type="text"
              id={"IFSC"}
              placeholder={`Enter IFSC`}
              onChange={(e) => {
                handleBankingDetailsChange("ifsc", e);
              }}
            />
          </FormControl>
        </Flex>

        <Flex align="start" mb={4} width="100%">
          <FormControl flex="1" mr={4}>
            <FormLabel
              style={{
                fontSize: "12px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              htmlFor={"Bank Branch Name"}
              color="#64748b"
            >
              Bank Branch Name
            </FormLabel>
            <Input
              value={formData.bankingDetails.bankBranchName}
              type="text"
              id={"Bank Branch Name"}
              placeholder={`Enter Bank Branch Name`}
              onChange={(e) => {
                handleBankingDetailsChange("bankBranchName", e);
              }}
            />
          </FormControl>
          <FormControl flex="1">
            <FormLabel
              style={{
                fontSize: "12px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              htmlFor={"UPI ID"}
              color="#64748b"
            >
              UPI ID
            </FormLabel>
            <Input
              value={formData.bankingDetails.upi}
              type="text"
              id={"UPI ID"}
              placeholder={`Enter UPI ID`}
              onChange={(e) => {
                handleBankingDetailsChange("upi", e);
              }}
            />
          </FormControl>
        </Flex>
      </Container>
    </>
  );
};

export default BankingDetailsFormSupplier;
