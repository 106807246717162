import { Tabs, TabList, Tab, Box } from "@chakra-ui/react";

interface TabBarProps {
  onChange: (index: number) => void;
}

function CustomerFeedbacksTab({ onChange }: TabBarProps): JSX.Element {
  function handleTabClick(index: number) {
    onChange(index);
  }

  return (
    <Box>
      <Tabs isFitted onChange={(index) => handleTabClick(index)}>
        <TabList mb="1em">
          <Tab>New</Tab>
          <Tab>Positive</Tab>
          <Tab>Neutral</Tab>
          <Tab>Negative</Tab>
        </TabList>
      </Tabs>
    </Box>
  );
}

export default CustomerFeedbacksTab;
