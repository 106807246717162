import endpoints from '../../utils/endpoints';
import { authAxios as axios } from '../../config/axios';
import globalConstant from '../../utils/constants';

class Services {

    async getdailyUrlDashboard() {
        const response = await axios({
            method: globalConstant.GET,
            url: `${endpoints.mobileConfig}`
        })
        return response.data;
    }

}

export default new Services()