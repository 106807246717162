import { Route, Routes } from "react-router-dom";
import CarList from "./components/CarList";
import EditCar from "./components/EditCar";

const Index = () => {
  return (
    <Routes>
      <Route path="/" element={<CarList></CarList>}></Route>
      <Route path="overview" element={<EditCar></EditCar>}></Route>
    </Routes>
  );
};

export default Index;
