import { LinkBox, LinkOverlay, Tag, Spinner, useDisclosure, Flex } from '@chakra-ui/react';
import { FormEvent } from 'react';
import Enum from '../enums';
import ConfirmModal from './ConfirmModal';
import DeleteModal from './DeleteModal';

type Props = {
  setActive?: (value: boolean) => void;
  isLoading?: boolean;
  data: ISingleComponentUser
};

const IsVerifiedButton = ({isLoading, setActive, data}: Props) => {
  const {isOpen, onClose, onOpen} = useDisclosure()
  const {isOpen: isDeleteOpen, onClose: onCloseDelete, onOpen: onOpenDelete} = useDisclosure()


  const handleActiveState = (e: FormEvent) => {
    e.preventDefault();
    onOpen()
  };

  const handleDeleteRole = (e: FormEvent) => {
    e.preventDefault();
    onOpenDelete()
  }

  return (
    <>
    <Flex justifyContent='center'>
    <LinkBox>
      {isLoading ? (
        <Spinner size='xs' />
      ) : (
        <LinkOverlay href='' onClick={handleActiveState}>
          <Tag colorScheme='green'>
            {Enum.VERIFY}
          </Tag>
        </LinkOverlay>
      )}
    </LinkBox>
    <LinkBox ml={3}>
      {isLoading ? (
        <Spinner size='xs' />
      ) : (
        <LinkOverlay href='' onClick={handleDeleteRole}>
          <Tag colorScheme='red' >
           {Enum.DECLINE}
          </Tag>
        </LinkOverlay>
      )}
    </LinkBox>
    </Flex>
    <ConfirmModal data={data} isOpen={isOpen} onClose={onClose}/>
    <DeleteModal data={data} isOpen={isDeleteOpen} onClose={onCloseDelete}/>
    </>
  );
};

export default IsVerifiedButton;
