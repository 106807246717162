import { Box, Flex, Alert, AlertIcon, useDisclosure } from '@chakra-ui/react';

import AddButton from '../../components/AddButton';
import BannerCard from './components/BannerCard';
import CreateBannerCard from './components/CreateBannerComponent';
import PageScaffold from '../../components/PageScaffold';
import Enum from './enums';

import { pageTitles } from '../../utils/paths';
import { useGetBanners } from './queryHooks';
import { Banner } from './types';


const Index = () => {
  const { data: banners, isLoading } = useGetBanners();
  const { onClose, onOpen, isOpen } = useDisclosure();

  return (
    <PageScaffold title={pageTitles.mediaManagement}>
      <Alert status='info'>
        <AlertIcon />
        Configure Mobile App Banners in this section . Changes here would be
        immediately applied to the running apps.
      </Alert>
      {isLoading ? (
        <Box mt={3}>...loading</Box>
      ) : (
        <Flex flexWrap='wrap'>
          {banners!.map((mediaObj: Banner) => {
            return <BannerCard key={mediaObj._id} {...mediaObj} />;
          })}
          <AddButton name={Enum.ADD_BANNER} onClick={onOpen} />
        </Flex>
      )}

      <CreateBannerCard isOpen={isOpen} onClose={onClose} />
    </PageScaffold>
  );
};

export default Index;
