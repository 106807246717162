/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormEvent,
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import {
  Center,
  Box,
} from '@chakra-ui/react';
import { FiUploadCloud } from 'react-icons/fi';

import MediaComponent from '../../../components/MediaComponent';
import CardScaffold from '../../../components/CardScaffold';
import ActiveButton from './ActiveButton';
import PriorityButton from './PriorityButton';
import SubmitAndCloseButton from '../../../components/SubmitAndCloseButton';
import ModalComponent from '../../../components/ModalComponent';
import Enums from '../enums';
import Clickable from '../../../components/Clickable';
import useNotification from "../../../utils/useNotification";
import globalConstant from '../../../utils/constants';
import FileInput from '../../../components/FileInput';

import { useCreateBanner, useGetBanners } from '../queryHooks';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

const CreateBannerCard = (props: Props) => {
  const toast = useNotification();
  const {data: banners} = useGetBanners()
  const {
    mutate: createBanner,
    isLoading: creatingBanner,
    isSuccess,
  } = useCreateBanner();
  const inputRef = useRef<HTMLInputElement>(null);
  const [priority, setPriority] = useState(0);
  const [active, setActive] = useState(false);
  const [path, setPath] = useState('');
  const [file, setFile] = useState<Blob>();


  const handleModalClose = () => {
    setFile(undefined);
    setPath('');
    setPriority(0);
    props.onClose();
  }

  useEffect(() => {
    if (isSuccess) {
      handleModalClose()
    }
  }, [isSuccess, props.onClose]);

  const handlePriorityChange = (value: number) => {
    setPriority(value);
  };

  const handleActiveChange = (value: boolean) => {
    setActive(value);
  };

  const handleImportFile = () => {
    inputRef?.current?.click();
  };

  const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    const file = e.currentTarget?.files![0];
    const path = URL.createObjectURL(file);
    setFile(file);
    setPath(path);
  };

  const handleCreateBanner = useCallback(() => {

    if(banners?.some(data => data.priority === priority)){
      toast({ description: Enums.PRIOTITY_ERROR, status: globalConstant.Error, title: Enums.NO_DUPLICATE_PRIORITY });
      return;
    }
    
    if (file) {
      const formData = new FormData();
      formData.append(Enums.MEDIA, file);
      formData.append(Enums.ACTIVE_STATE, JSON.stringify(active));
      formData.append(Enums.PRIORITY, JSON.stringify(priority));

      createBanner({ data: formData });
    }
  }, [active, createBanner, file, priority]);

  const bottomButton = useMemo(() => {
    return (
      <SubmitAndCloseButton
        isDisabled={!Boolean(file) || !Boolean(priority)}
        isLoading={creatingBanner}
        onSubmit={handleCreateBanner}
        onClose={handleModalClose}
      />
    );
  }, [file, priority, creatingBanner, handleCreateBanner, props.onClose]);

  return (
    <ModalComponent
      button={bottomButton}
      isOpen={props.isOpen}
      title={Enums.ADD_BANNER}
      onClose={handleModalClose}
    >
      <CardScaffold
        width='100%'
        media={
          Boolean(path) ? (
            <Clickable onClick={handleImportFile}>
              <MediaComponent
                path={path}
                isVideo={file?.type.startsWith('video') ?? false}
              />
            </Clickable>
          ) : (
            <Clickable onClick={handleImportFile}>
              <Center height='100%' fontSize='5vw'>
                <FiUploadCloud />
              </Center>
            </Clickable>
          )
        }
        leftMenu={
          <>
            <ActiveButton active={active} setActive={handleActiveChange} />
            <Box>
              <PriorityButton
                priority={priority}
                setPriority={handlePriorityChange}
                isCreating={true}
              />
            </Box>
          </>
        }

      />
      <FileInput ref={inputRef} onChange={handleInputChange}/>
    </ModalComponent>
  );
};
export default CreateBannerCard;
