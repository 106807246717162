import * as yup from 'yup';
import Enums from '../enums'

export const phoneNumberFormSchema = yup
  .object()
  .shape({
    phoneNumber: yup
      .string()
      .matches(/^\d{10}$/, Enums.INVALID_PHONE_NUMBER_ERROR)
      .max(10, Enums.TEN_DIGIT_ERROR)
      .min(10, Enums.TEN_DIGIT_ERROR)
      .trim()
      .required(Enums.PHONE_NUMBER_REQUIRED),
  })
  .required();