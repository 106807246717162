import VideoComponent from './VideoComponent';
import ImageComponent from './ImageComponent';

type Props = {
  isVideo: boolean;
  path: string
};

const MediaComponent = (props: Props) => {
  return props.isVideo ? (
    <VideoComponent  path={props.path} />
  ) : (
    <ImageComponent path={props.path} />
  );
};

export default MediaComponent;