import React, { useEffect, useState } from "react";
import {
  Box,
  Image,
  Center,
  VStack,
  Text,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { useQueryClient } from "@tanstack/react-query";
import globalConstant from "../utils/constants";
import getUserRole from "../utils/getUserRole";
import GroupNav from "./GroupNav";
import UngroupedNav from "./UngroupNav";
import NavMole from "./NavMole";
import removeUnderScore from "../utils/removeUnderscore";
import pagePaths, { NavNames, pathObject } from "../utils/paths";
import { useUserDetailsContext } from "../Contexts/userDetailsContext";

type Props = {
  isOpen: boolean;
};

const Index = (props: Props) => {
  const userDetails = useUserDetailsContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [role, setRole] = useState<any>();
  useEffect(() => {
    const role = userDetails.roles.filter(
      (components: { component: string }) =>
        components.component === globalConstant.MISSION_CONTROL
    );
    if (role.length !== 0) {
      setRole(role[0].role);
    }
  }, [userDetails]);

  const navs = pathObject
    .filter((item) =>
      item.rolesAllowed.includes(getUserRole(userDetails) as any)
    )
    .map((item) => {
      const mainName = item.main.name;
      if (
        [
          NavNames.LiveDashboard,
          NavNames.sparePartCompatibility,
          NavNames.boodmoModel,
          NavNames.dashboards,
          NavNames.subscriptions,
          NavNames.feedbacks,
          NavNames.suppliers,
          NavNames.customers,
          NavNames.grazeons,
        ].includes(mainName)
      ) {
        return (
          <UngroupedNav
            name={item.main.name}
            path={item.sub[0].path}
            key={item.main.name}
          />
        );
      } else if (role === globalConstant.AUDITOR) {
        if (
          item.main.name === globalConstant.METRICS ||
          item.main.name === globalConstant.LIVE_DASHBOARD
        ) {
          return <GroupNav {...item} key={item.main.name} />;
        }
      } else {
        return <GroupNav {...item} key={item.main.name} />;
      }
    });

  const handleLogout = (e: React.FormEvent) => {
    e.preventDefault();
    localStorage.removeItem(globalConstant.MC_SUPER_ADMIN);
    queryClient.cancelQueries();
    queryClient.clear();
    navigate(pagePaths.login);
  };

  return (
    <VStack
      bgColor="white"
      width="100%"
      height="100%"
      borderRight="1px solid"
      borderColor="gray.200"
    >
      <VStack borderBottom="1px solid" borderColor="gray.200" h="25%" w="100%">
        <Center h="100%">
          <VStack>
            <Box>
              <Image src="/images/logo.png" boxSize="82" />
            </Box>
            <Box>
              <Text variant="boldNormal" textAlign="center">
                Mission Control
              </Text>
              <Text
                variant="normal"
                textAlign="center"
              >{`${userDetails?.firstName} ${userDetails?.lastName}`}</Text>
              <Text fontSize="xs" textAlign="center">
                ({removeUnderScore(`${getUserRole(userDetails)}`)})
              </Text>
            </Box>
          </VStack>
        </Center>
      </VStack>
      <Box
        display="flex"
        flexDirection="column"
        height="78%"
        width="100%"
        pl="4"
        pt="5"
        overflow="auto"
      >
        <>{navs}</>

        <Box mt="auto">
          <LinkBox>
            <LinkOverlay href="" onClick={handleLogout}>
              <NavMole isLogout={true} name="Logout" icon={<FiLogOut />} />
            </LinkOverlay>
          </LinkBox>
        </Box>
      </Box>
    </VStack>
  );
};

export default Index;
