enum Enums{
    Error='error',
    Success='success',
    OTP_SENT_MESSAGE='OTP has been sent, successfully',
    OTP_SENT_TITLE='OTP Sent',
    UNAUTHORIZED_MESSAGE='You are not authorised',
    UNAUTHORIZED_TITLE='Authorisation Denied',
    MOBILE_NUMBER_PREFIX='91',
    USEVISIT_ERROR='useVisit is not defined within VisitProvider',
    ERROR_MESSAGE='Error occured, please try again',
    UNDEFINED='undefined',
    INVALID_PHONE_NUMBER_ERROR='Enter a valid phone number',
    TEN_DIGIT_ERROR='phone number must not be more than 10 digits',
    PHONE_NUMBER_REQUIRED='Phone number is required'
}

export default Enums;