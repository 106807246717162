import React from "react";
import { supplierFormData } from "./AddSupplier";
import { Box, Tag, TagLabel, Textarea, Text } from "@chakra-ui/react";
import { useGetMobileConfig } from "../../Customers/queryHooks";
import { secondaryColors, successColors } from "../../../utils/designSystem";

type SupplierRemarksProps = {
  formData: supplierFormData;
  setFormData: React.Dispatch<React.SetStateAction<supplierFormData>>;
  handleBasicInfoValueChange: (second: any, e: any) => void;
};

const SupplierRemarks: React.FC<SupplierRemarksProps> = ({
  formData,
  setFormData,
  handleBasicInfoValueChange,
}) => {
  const { data: supplierRatingTypes } = useGetMobileConfig();

  return (
    <>
      <Textarea
        placeholder="Add your notes here..."
        value={formData?.basicInfo?.remarks}
        onChange={(e) => {
          handleBasicInfoValueChange("remarks", e);
        }}
      />

      <Box mt={5} mb={5}>
        <Text fontSize={"xs"} color={"#64748B"} fontWeight={"bold"} mb={5}>
          SUPPLIER RATING
        </Text>
        {supplierRatingTypes?.supplier_rating_types?.map(
          (item: any, index: number) => {
            return (
              <Tag
                key={index}
                mr={3}
                cursor={"pointer"}
                bgColor={
                  item === formData.basicInfo.rating
                    ? `${successColors.success_110}`
                    : "white"
                }
                outline={`2px solid ${secondaryColors.secondary_100}`}
                padding={2}
                onClick={() => {
                  if (item === formData.basicInfo.rating) {
                    setFormData((previousFormData) => {
                      return {
                        ...previousFormData,
                        basicInfo: {
                          ...previousFormData.basicInfo,
                          rating: "",
                        },
                      };
                    });
                  } else {
                    setFormData((previousFormData) => {
                      return {
                        ...previousFormData,
                        basicInfo: {
                          ...previousFormData.basicInfo,
                          rating: item,
                        },
                      };
                    });
                  }
                }}
              >
                <TagLabel>{item}</TagLabel>
              </Tag>
            );
          }
        )}
      </Box>
    </>
  );
};

export default SupplierRemarks;
