import PageScaffold from "../../components/PageScaffold";
import { pageTitles } from "../../utils/paths";
import GaraazTable, { COLUMN_TYPES } from "../../components/GaraazTable";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AddSupplier from "./components/AddSupplier";
import { secondaryColors } from "../../utils/designSystem";
import SupplierInfo from "./components/SupplierInfo";
import { Button } from "@chakra-ui/react";

const Index = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const TableHeading = [
    {
      header: "Supplier Name",
      accessor: "businessName",
      type: COLUMN_TYPES.NAME,
    },
    {
      header: "Primary Contact Number",
      accessor: "mobileNumber",
      type: COLUMN_TYPES.MOBILE_WITH_SIZE,
    },
    {
      header: "Secondary Contact Number",
      accessor: "secondaryContactNumber",
      type: COLUMN_TYPES.MOBILE_WITH_SIZE,
    },
  ];
  const row = [10, 20, 30, 50, 100, 150];
  const value = 50;
  const handleAddSupplier = () => {
    navigate(`${pathname}/add`);
  };

  const handleSupplierRowClick = (row: any) => {
    navigate(`${pathname}/${row._id}/overview`);
  };

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PageScaffold
            title={pageTitles.suppliers}
            hasButton={true}
            buttonText={
              <Button
                onClick={handleAddSupplier}
                position={"fixed"}
                right={4}
                border={` 1px solid ${secondaryColors.secondary_100}`}
                padding={"7px 10px 7px 10px"}
              >
                {" "}
                + Add
              </Button>
            }
          >
            <GaraazTable
              columns={TableHeading}
              url={`${process.env.REACT_APP_BASE_URL}/v1/suppliers`}
              limits={row}
              defaultRowLimit={value}
              handleRowClick={handleSupplierRowClick}
            />
          </PageScaffold>
        }
      ></Route>
      <Route path="add" element={<AddSupplier />}></Route>
      <Route path="/:id/overview" element={<SupplierInfo />}></Route>
    </Routes>
  );
};
export default Index;
